import {Alert, Box, Button, Grid, Group, Text} from "@mantine/core";
import {IconCheck, IconX} from "@tabler/icons-react";
import Modal, { Props as ModalProps } from "./Modal";
import { client } from "../../lib/axiosClient";
import {Notyf} from "notyf";
import { useEffect, useState } from "react";
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { ClientsMarketerTabel } from "../Tables/ClientsMarketer";
import { useClients, useClientsMarketer } from "../../api";
import { sortedData } from "../../lib/sort";

const cookies = new Cookies(null, { path: '/' });
const {Col} = Grid

type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

export const ShowClientsMarketer = ({data, ...props}: Props) => {
    const { t } = useTranslation();
    const [orders, setOrders] = useState<any[]>([]);
    const [ordersSorted, setOrdersSorted] = useState<any[]>([]);
    const {loading, error, data: dataOrders, refetch, fetchData} = useClientsMarketer()

    useEffect(() => {
        if (props.opened && data !== null) {
            fetchData({
                status: "payment", referralCode: data?.referralCode
            })
        }
    }, [data, props.opened])

    useEffect(() => {
        if (dataOrders) {
            setOrders(dataOrders)
            const newData = sortedData(dataOrders)
            setOrdersSorted(newData)
        }
    }, [dataOrders])
    
    const closeModal = () => {
        props.onClose();
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading} size="90%" closeOnClickOutside={true}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} onClick={closeModal}>{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            <Box p={20} maw={"100%"}>
                <ClientsMarketerTabel
                    data={ordersSorted}
                />
            </Box>
        </Modal>
    );
};