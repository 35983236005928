import { Box, Button, Checkbox, CloseButton, ColorInput, Divider, Grid, Group, Image, NumberInput, PasswordInput, Select, Switch, Tabs, Text, TextInput, Textarea, rem } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { client } from "../../lib/axiosClient";
import { IconCheck, IconPhoto, IconUpload, IconX, IconXboxX } from "@tabler/icons-react";
import { zodResolver } from 'mantine-form-zod-resolver';
import { z } from 'zod';
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import Wilayas from '../../helper/wilayas.json';
import { useStore } from "../../api";
import { DateTimePicker } from "@mantine/dates";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    data?: any;
    refetchData?: () => void;
} & ModalProps;

const dataStatus = [
    {group: "طلب جديد", items: [
        {label: "طلب جديد", value: "new"},
        {label: "المحاولة الاولى", value: "new-testing"},
        {label: "المحاولة الثانية", value: "new-testing-2"},
        {label: "المحاولة الثالثة", value: "new-testing-3"},
        {label: "مؤجل", value: "new-delayed"},
        {label: "لم يرد", value: "new-not-answer"},
        {label: "رفض", value: "new-rejected"}
    ]},
    {group: "قيد التجريب", items: [
        {label: "قيد التجريب", value: "testing"},
        {label: "تاكيد مؤقت", value: "testing-confirmed"},
        {label: "المعاينة الاولى", value: "testing-attempted"},
        {label: "المعاينة الثانية", value: "testing-attempted-2"},
        {label: "المعاينة الثالثة", value: "testing-attempted-3"},
        {label: "مؤجل", value: "testing-delayed"},
        {label: "لم يرد", value: "testing-not-answer"},
        {label: "رفض", value: "testing-rejected"},
    ]},
    {group: "مؤكد", items: [
        {label: "مؤكد", value: "payment"}
    ]}
]

export const UpdateClientStatus = ({data, ...props}: Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const {onSubmit, reset, getInputProps, setValues, values} = useForm({
        initialValues: { status: ""}
    });

    const onSubmitForm = ({status}: any) => {
        setLoading(true)
        
        client.put(`/clients/${data}`, {status}, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: "تم تغيير حالة العميل", color: '#fff' });
            console.log(data);
            setLoading(false)
            typeof props.refetchData == "function" && props.refetchData()
            closeModal()
        })
        .catch(({response}) => {
            notifications.show({ message: "فشل تغيير حالة العميل", color: '#fff' });
            setLoading(false)
        });
    }

    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={{padding: 20}}>
                <form onSubmit={onSubmit(onSubmitForm)} id="submit_form">
                    <Grid gutter={20} justify="flex-start">
                        <Grid.Col span={{ base: 12, sm: 12, md: 12 }}>
                            <Select
                                label={"حالة الطلب"}
                                placeholder={"حالة الطلب"}
                                data={dataStatus}
                                {...getInputProps('status')}
                                allowDeselect={false}
                            />
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};