import { Box, Button, Checkbox, CloseButton, ColorInput, Divider, Grid, Group, Image, NumberInput, PasswordInput, Select, Switch, Tabs, Text, TextInput, Textarea, rem } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { client } from "../../lib/axiosClient";
import { IconCheck, IconPhoto, IconUpload, IconX, IconXboxX } from "@tabler/icons-react";
import { zodResolver } from 'mantine-form-zod-resolver';
import { z } from 'zod';
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import Wilayas from '../../helper/wilayas.json';
import { useStore, useUsers } from "../../api";
import { DateTimePicker } from "@mantine/dates";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    data?: any;
    refetchData?: () => void;
} & ModalProps;

export const UpdateConfermer = ({data, ...props}: Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const {onSubmit, reset, getInputProps, setValues, values} = useForm({
        initialValues: { confirmedID: ""}
    });
    const [confirmed, setConfirmed] = useState<{label: string, value: string}[]>([]);
    const {data: dataUser, refetch} = useUsers()

    useEffect(() => {
        if (dataUser?.length >= 0) {
            let newData: {label: string, value: string}[] = []
            for (let i = 0; i < dataUser.length; i++) {
                const element = dataUser[i];
                if (element.role === "confirmed") {
                    newData.push({label: element.name, value: element._id})
                }
            }
            setConfirmed(newData)
        }
    }, [dataUser])
    
    useEffect(() => {
        if (props.opened && data !== null) {
            setValues({
                confirmedID: data.confirmedID
            })
        }
    }, [data, props.opened])

    const onSubmitForm = ({confirmedID}: any) => {
        setLoading(true)
        
        client.put(`/clients/${data._id}`, {
            confirmedID
        }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: "تم تغيير مؤكد الطلبات", color: '#fff' });
            console.log(data);
            setLoading(false)
            typeof props.refetchData == "function" && props.refetchData()
            closeModal()
        })
        .catch(({response}) => {
            notifications.show({ message: "فشل تغيير مؤكد الطلبات", color: '#fff' });
            setLoading(false)
        });
    }

    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={{padding: 20}}>
                <form onSubmit={onSubmit(onSubmitForm)} id="submit_form">
                    <Grid gutter={20} justify="flex-start">
                        <Grid.Col span={12} >
                            <Select
                                label={"مؤكد الطلبات"}
                                placeholder={"مؤكد الطلبات"}
                                data={confirmed}
                                withAsterisk
                                {...getInputProps("confirmedID")}
                                allowDeselect={false}
                            />
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};