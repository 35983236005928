import {ActionIcon, Badge, Divider, Group, Image, Menu, rem, Skeleton, Stack, Switch, Text, useMantineTheme} from '@mantine/core';
import {IconDotsVertical, IconEdit, IconTrash, IconCircleXFilled, IconCircleCheckFilled, IconEye, IconPencil, IconDatabaseOff, IconCheck, IconX, IconPower} from '@tabler/icons-react';
import DataTable, {TableStyles} from 'react-data-table-component';
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';
import Avatar from 'react-avatar';
import { notifications } from '@mantine/notifications';
import { client } from '../../lib/axiosClient';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';

const cookies = new Cookies(null, { path: '/' });
const customStyles: TableStyles = {
    table: {
        style: {
            minHeight: 380,
            border: "2px solid #E0E2E7",
            borderRadius: "8px"
        },
    },
    headRow: {
        style: {
            borderRadius: "8px 8px 0 0",
            ":nth-child(1)": {
                borderRadius: "0 8px 0 0",
            },
            ":nth-last-child(1)": {
                borderRadius: "8px 0 0 0"
            }
        },
    },
    headCells: {
        style: {
            fontWeight: 800,
            fontSize: 14,
            background: "#F9F9FC",
            height: "56px",
            ":nth-child(1)": {
                background: "#F9F9FC",
            }
        }
    },
    cells: {
        style: {
            height: "60px"
        }
    }
};

type Props = {
    setSelectedData: (data: any) => void;

    setOpenEditModal: (status: boolean) => void;
    setOpenDeleteModal: (status: boolean) => void;
    setOpenShowModal: (status: boolean) => void;
    data?: any;
    loading?: boolean;
    refetchData: () => void
};


const roles = {
    "admin": "مدير",
    "responsible": "مانجر",
    "confirmed": "مؤكد طلبات",
    "marketer": "مسوق/مندوب"
}

export const UsersTabel = ({data, loading, setSelectedData, setOpenEditModal, setOpenDeleteModal, setOpenShowModal, refetchData}: Props) => {
    const { t } = useTranslation();
    const theme = useMantineTheme();

    const updateStatusConfirmed = (status: boolean, id: string) => {
        client.put(`/users/${id}`, {
            "acceptingOrder": status,
        }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: "تم تغيير حالة مؤكد الطلبات", color: '#fff' });
            console.log(data);
            refetchData()
        })
        .catch((error) => {
            notifications.show({ message: "فشل تغيير حالة مؤكد الطلبات", color: '#fff' });
        });
    }

    const columns = [
        {name: t('tables.users.row01'), selector: (row: any, index: number) => (
            <Group justify='flex-start' align='center' gap={8}>
                <Avatar name={row.name} size="30" round={true} />
                <Stack justify='center' align='flex-start' gap={5}>
                    <Text size='14px' c={"#333843"} fw={500}>{row.name}</Text>
                </Stack>
            </Group>
        ), minWidth: "200px"},
        {name: t('tables.users.row02'), selector: (row: any, index: number) => row?.email, minWidth: "200px" },
        {name: t('tables.users.row03'), selector: (row: any, index: number) => row?.phone, minWidth: "200px" },
        {name: t('tables.users.row04'), selector: (row: any, index: number) => (
            <Badge radius={'sm'} color='gray' variant='light'>{
                // @ts-ignore
                roles[row?.role as typeRoles]
            }</Badge>
        ) , minWidth: "200px", sortable: true, sortFunction: (a: any, b: any) => a?.role > b?.role ? 1 : -1 },
        {name: "حالة العمل", selector: (row: any, index: number) => (
            row?.role && row?.role == "confirmed" 
                ? <Switch
                    checked={row?.acceptingOrder}
                    onChange={(event) => updateStatusConfirmed(event.currentTarget.checked, row?._id)}
                    style={{cursor: "pointer"}}
                    color='teal'
                    thumbIcon={
                        row?.acceptingOrder ? (
                          <IconCheck
                            style={{ width: rem(12), height: rem(12) }}
                            color={theme.colors.teal[6]}
                            stroke={3}
                          />
                        ) : (
                          <IconPower
                            style={{ width: rem(12), height: rem(12) }}
                            color={theme.colors.red[6]}
                            stroke={3}
                          />
                        )
                    }
                />
                : null
        ) , minWidth: "200px" },
        {name:t('tables.users.row05'), selector: (row: any) => dayjs(row.createdAt).locale("ar").fromNow(), minWidth: "200px", sortable: true, sortFunction: (a: any, b: any) => a?.createdAt > b?.createdAt ? 1 : -1 },

        {name: t('tables.users.row06'), cell: (row: any) => (
            <Group wrap={"nowrap"} justify={"center"} w={"100%"} gap={2}>
                {row?.role == "marketer"
                    ? <ActionIcon
                        variant={"transparent"} color={"#667085"}
                        component={Link} to={`/dashboard/marketer/${row?._id}`}
                        // onClick={() => {
                        //     setSelectedData(row)
                        //     setOpenShowModal(true)
                        // }}
                    >
                        <IconEye size="1.125rem" />
                    </ActionIcon>
                    : null
                }
                
                <ActionIcon
                    variant={"transparent"} color={"#667085"}
                    onClick={() => {
                        setSelectedData(row)
                        setOpenEditModal(true)
                    }}
                >
                    <IconPencil size="1.125rem" />
                </ActionIcon>
                <ActionIcon
                    variant={"transparent"} color={"#667085"}
                    onClick={() => {
                        setSelectedData(row)
                        setOpenDeleteModal(true)
                    }}
                >
                    <IconTrash size="1.125rem" />
                </ActionIcon>
            </Group>
        ), allowOverflow: true, button: true, minWidth: "100px" }
    ];

    return (
        <DataTable
            // @ts-ignore
            columns={columns}
            data={data}
            customStyles={customStyles}
            highlightOnHover
            pagination={true}
            persistTableHead={true}
            progressPending={loading || false}
            progressComponent={<LoadingTable />}
            noDataComponent={
                <Stack align='center' justify='center' p={10}>
                    <Image src={"/emptyData.png"} mah={150} w={"auto"} />
                    <Text size='14px' c={"#667085"}>{t('tables.emptyLable')}</Text>
                </Stack>
            }
            paginationComponentOptions={{
                rowsPerPageText: t('tables.rangeSeparatorText'),
                rangeSeparatorText: t('tables.rangeSeparatorText')
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100, 200]}
            paginationPerPage={100}
        />
    );
};


const LoadingTable = () => {
    const numRows = [0,0,0];
    return (
        <Stack w={"100%"} p={0} m={0} gap={0}>
            {numRows.map((item, index) => (
                <RowSkeleton key={index}/>
            ))}
        </Stack>
    )
}

const RowSkeleton = () => {
    return (
        <Group w={"100%"} mih={48} align='stretch' gap={0} wrap='nowrap' style={{borderBottom: "2px solid #E0E2E7"}}>
            <Group h={60} miw={"40%"} px={16} justify='flex-start' align='center' gap={8} wrap='nowrap'>
                <Skeleton width={44} height={44} />
                <Stack justify='center' align='flex-start' gap={5}>
                    <Skeleton height={14} width={100} />
                </Stack>
            </Group>
            <Group h={60} miw={"25%"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={100} />
            </Group>
            <Group h={60} miw={"25%"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"25%"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={100} />
            </Group>
            <Group h={60} miw={"25%"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"10%"} px={16} justify='flex-start' align='center'>
                <Group wrap={"nowrap"} justify={"center"} w={"100%"} gap={2}>
                    <Skeleton height={18} width={18} />
                    <Skeleton height={18} width={18} />
                </Group>
            </Group>
        </Group>
    );
}