import {Group, Box, Burger, Image, ActionIcon, Indicator, useDirection, Menu, useMantineTheme, Dialog, TextInput, Button } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Emitter from '../../lib/Emitter';
import { IconBell, IconLanguage, IconSearch } from '@tabler/icons-react';
import { useAudio } from 'react-use-audio';
//@ts-ignore
import notificationFile from "../../Notification.mp3";
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { ShowClientDrawer } from '../Drawer';
import { client } from '../../lib/axiosClient';
import { notifications } from '@mantine/notifications';
const cookies = new Cookies(null, { path: '/' });

export function Navbar ({sideBarOpened, toggleSideBar}: {sideBarOpened: boolean; toggleSideBar: () => void}) {
    let location = useLocation();
    let them = useMantineTheme();
    const navigate = useNavigate();
    const [notification, setNotification] = useState(false);
    const { play, stop, data } = useAudio(notificationFile);
    const { t, i18n, ready } = useTranslation();
    const { dir, setDirection } = useDirection();
    const [openedSearchDialog, { close: closeSearchDialog, open: openSearchDialog }] = useDisclosure(false);
    const [openShowModal, setOpenShowModal] = useState(false);
    const [search, setSarch] = useState<any>(null);
    const [selectedData, setSelectedData] = useState<any>(null);
    const isMobile = useMediaQuery("(max-width: 48em)");
    

    useEffect(() => {
        Emitter.on("newOrder", (data: any) => {
            play();
            setNotification(true)
        })
    }, [])
    

    
    const onSearch = () => {
        client.get(`/clientSearch/${search}`, {
            headers: {'Accept': 'application/json', 'Authorization': cookies.get('token') || ""}
        })
        .then(({data}) => {
            if (data) {
                setSelectedData(data);
                setOpenShowModal(true)
                closeSearchDialog()
                setSarch(null)
            } else {
                notifications.show({ message: "هذا الشخص غير موجود", color: 'red' });
            }
        })
        .catch(({response}) => {
            notifications.show({ message: "هذا الشخص غير موجود", color: 'red' });
        });
    }

    return (
        <Box p={0} m={0} h={60} display={'flex'}>
            <Group justify="space-between" h="100%" w="100%" wrap='nowrap' gap={0}>
                {!sideBarOpened
                    ? <Group justify='center' align='center' h={60} miw={220} visibleFrom='md' style={{borderLeft: "1px solid #dee2e6", borderBottom: "1px solid #fff"}}>
                        <Image src={"/Bi3li_logo.png"} fit='contain' height={45} width={"auto"} />
                    </Group>
                    : null
                }

                <Group justify="space-between" align='center' h="100%" w="100%" px={{ base: "xs", sm: 'sm', md: 'md', lg: 'lg', xl: 'xl' }} >
                    <Burger opened={false} onClick={toggleSideBar} size={'sm'} />

                    <Image src={"/Bi3li_logo.png"} fit='contain' height={45} width={"auto"} hiddenFrom='md' />

                    <Group justify="flex-end" align='center' h="100%">
                        {/* <Menu shadow="md" width={120}>
                            <Menu.Target>
                                <ActionIcon variant="transparent" color={them.colors.gray[7]}>
                                    <IconLanguage size={25} />
                                </ActionIcon>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Item onClick={() => onChangeLang("en")}>{t('listLanguages.english')}</Menu.Item>
                                <Menu.Item onClick={() => onChangeLang("ar")}>{t('listLanguages.arabic')}</Menu.Item>
                            </Menu.Dropdown>
                        </Menu> */}
                        <ActionIcon
                            variant="transparent" color={them.colors.gray[7]}
                            onClick={() => openSearchDialog()}
                        >
                            <IconSearch size={25} stroke={1.5} />
                        </ActionIcon>
                        
                        <Indicator inline processing color="red" size={10} h={28} disabled={!notification}>
                            <ActionIcon
                                variant="transparent" color={them.colors.gray[7]}
                                onClick={() => {
                                    navigate("/dashboard/orders");
                                    setNotification(false)
                                }}
                            >
                                <IconBell size={25} stroke={1.5} />
                            </ActionIcon>
                        </Indicator>
                    </Group>
                </Group>
            </Group>
            <Dialog
                opened={openedSearchDialog} withCloseButton onClose={closeSearchDialog}
                size={"lg"} radius="md" position={isMobile ? { top: 0, right: 0, left: 0 } : { top: 70, right: 70 }}
                styles={{
                    root: {
                        width: "100%"
                    }
                }}
            >
                <Group w={"100%"} mt={20} justify='space-between' align="center" wrap='nowrap'>
                    <TextInput
                        placeholder={"ادخل رقم الهاتف او البريد الالكتروني"}
                        w={"100%"}
                        value={search}
                        onChange={(event) => setSarch(event.currentTarget.value)}
                    />
                    
                    <Button
                        variant={"outline"}
                        color={'#323232'} px={10} miw={70}
                        onClick={onSearch}
                    >بحث</Button>
                </Group>
            </Dialog>
            <ShowClientDrawer title={"تفاصيل العميل"} data={selectedData} opened={openShowModal} onClose={() => setOpenShowModal(false)} />
            
        </Box>
    );
}