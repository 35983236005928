import {ActionIcon, Alert, Autocomplete, Box, Button, CheckIcon, CloseButton, Divider, Grid, Group, Image, List, NumberInput, PasswordInput, Radio, Select, Stack, Stepper, Switch, Text, TextInput, Textarea, Tooltip, rem} from "@mantine/core";
import {IconCheck, IconCloudUpload, IconMicrophone, IconMicrophone2, IconPlayerStopFilled, IconTrash, IconUpload, IconX} from "@tabler/icons-react";
import Modal, { Props as ModalProps } from "./Modal";
import { client } from "../../lib/axiosClient";
import { Notyf } from "notyf";
import { useEffect, useState } from "react";
import { notifications } from "@mantine/notifications";
// import {Notyf} from "notyf";

import Cookies from "universal-cookie";
import { useForm } from "@mantine/form";
import { zodResolver } from 'mantine-form-zod-resolver';
import { z } from 'zod';
import Wilayas from '../../helper/wilayas.json';
import Communes from '../../helper/communes.json';
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { RichText } from "../Custom";

import { ReactMediaRecorder, useReactMediaRecorder,  } from 'react-media-recorder';

const cookies = new Cookies(null, { path: '/' });
const {Col} = Grid

type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData: any;
} & ModalProps;

export const ChangeStatusInvoiceMarketer = ({data, refetchData, ...props}: Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const onFormSubmit = () => {
        setLoading(true)
        client.put(`/change-status-invoice/${data?._id}`, {
            "status": data?.status
        }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: "تم تغيير حالة الفاتورة", color: '#fff' });
            setLoading(false)
            typeof refetchData == "function" && refetchData()
            closeModal()
        })
        .catch((error) => {
            notifications.show({ message: "فشل تغيير حالة الفاتورة", color: '#fff' });
            setLoading(false)
            closeModal()
        });
    };

    const closeModal = () => {
        props.onClose();
        setLoading(false)
    };
    
    return (
        <Modal
            {...props} onClose={closeModal} loading={false} size="lg"
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} onClick={onFormSubmit}>{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
            title={data?.status === "accepted" ? "تأكيد الفاتورة" : "رفض الفاتورة"}
        >
            <Box style={({ colors }) => ({padding: 20})}>
                <Grid gutter={20}>
                    <Col span={12} >
                        <Alert color={data?.status === "accepted" ? "blue" : "red"}>
                            {"هل انت متأكد من "} {data?.status === "accepted" ? "تأكيد" : "رفض"} {" الفاتورة؟"}
                        </Alert>
                    </Col>
                </Grid>
            </Box>
        </Modal>
    );
};