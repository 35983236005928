import {ActionIcon, Badge, Divider, Group, HoverCard, Image, List, Menu, Skeleton, Stack, Text, Tooltip, rem} from '@mantine/core';
import {IconDotsVertical, IconEdit, IconTrash, IconEye, IconDashboard, IconCloudUpload, IconCashBanknote, IconMail, IconMailForward} from '@tabler/icons-react';
import DataTable, {TableStyles} from 'react-data-table-component';
import dayjs from "dayjs";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

const customStyles: TableStyles = {
    table: {
        style: {
            minHeight: 380,
            border: "2px solid #E0E2E7",
            borderRadius: "8px"
        },
    },
    headRow: {
        style: {
            borderRadius: "8px 8px 0 0",
            ":nth-child(1)": {
                borderRadius: "0 8px 0 0"
            },
            ":nth-last-child(1)": {
                borderRadius: "8px 0 0 0"
            }
        },
    },
    headCells: {
        style: {
            fontWeight: 800,
            fontSize: 14,
            background: "#F9F9FC",
            height: "56px",
            ":nth-child(1)": {
                background: "#F9F9FC",
            }
        }
    },
    cells: {
        style: {
            height: "60px"
        }
    }
};

type Props = {
    data?: any;
    loading?: boolean;
};

const cookies = new Cookies(null, { path: '/' });

export const ClientsMarketerTabel = ({data, loading}: Props) => {
    const { t } = useTranslation();
    const [role, setRole] = useState("");

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])

    const getLastTrace = (data: any) => {
        if (!data) return []
        const length = data.length
        return data[length-1]
    }

    const columns = [
        {name: "اسم العميل", selector: (row: any, index: number) => row?.name, minWidth: "170px"},
        {name: "رقم الهاتف", selector: (row: any, index: number) => row?.phone, minWidth: "120px"},
        {name: "البريد الالكتروني", selector: (row: any, index: number) => row?.email, minWidth: "170px"},
        {name: "الخطة", selector: (row: any, index: number) => (
            row?.version === "basic" ? <Badge radius={'sm'} color='blue' variant='filled'>{"الخطة البسيطة"}</Badge> 
                : row?.version === "pro" ? <Badge radius={'sm'} color='grape' variant='filled'>{"الخطة الاحترافية"}</Badge> 
                    : row?.version === "custom" ? <Badge radius={'sm'} color='lime' variant='filled'>{"الخطة المخصصة"}</Badge> : null
        ), minWidth: "140px", sortable: true, sortFunction: (a: any, b: any) => a?.version > b?.version ? 1 : -1},
        {name: "الثيم", selector: (row: any, index: number) => (
            row?.theme === "nova" ? <Badge radius={'sm'} color='green' variant='filled'>{"Nova"}</Badge> 
                : row?.theme === "nexa" ? <Badge radius={'sm'} color='#d44c2e' variant='filled'>{"Nexa"}</Badge> 
                    : row?.theme === "solo" ? <Badge radius={'sm'} color='purple' variant='filled'>{"Solo"}</Badge> 
                    : row?.theme === "anaqati" ? <Badge radius={'sm'} color='#b07d10' variant='filled'>{"Anaqati"}</Badge> : null
        ), minWidth: "50px", sortable: true, sortFunction: (a: any, b: any) => a?.theme > b?.theme ? 1 : -1},

        {name: "تاريخ التسجيل", selector: (row: any) => dayjs(row?.createdAt).locale("ar").fromNow(), minWidth: "120px", sortable: true, sortFunction: (a: any, b: any) => a?.createdAt > b?.createdAt ? 1 : -1 }
    ];

    return (
        <DataTable
            // @ts-ignore
            columns={columns}
            data={data}
            customStyles={customStyles}
            highlightOnHover
            persistTableHead={true}
            progressPending={loading || false}
            progressComponent={<LoadingTable />}
            noDataComponent={
                <Stack align='center' justify='center' p={10}>
                    <Image src={"/emptyData.png"} mah={150} w={"auto"} />
                    <Text size='14px' c={"#667085"}>{t('tables.emptyLable')}</Text>
                </Stack>
            }

            pagination={true}
            paginationComponentOptions={{
                rowsPerPageText: t('tables.rangeSeparatorText'),
                rangeSeparatorText: t('tables.rangeSeparatorText')
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100, 200]}
            paginationPerPage={100}
        />
    );
};


const LoadingTable = () => {
    const numRows = [0,0,0];
    return (
        <Stack w={"100%"} p={0} m={0} gap={0}>
            {numRows.map((item, index) => (
                <RowSkeleton key={index}/>
            ))}
        </Stack>
    )
}

const RowSkeleton = () => {
    
    return (
        <Group w={"100%"} mih={48} align='stretch' gap={0} wrap='nowrap' style={{borderBottom: "2px solid #E0E2E7"}}>
            <Group h={60} miw={"250px"} px={16} justify='flex-start' align='center' gap={8} wrap='nowrap'>
                <Skeleton width={44} height={44} />
                <Stack justify='center' align='flex-start' gap={5}>
                    <Skeleton height={14} width={120} />
                    <Skeleton height={12} width={60} />
                </Stack>
            </Group>
            <Group h={60} miw={"170px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"120px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"110px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"110px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
        </Group>
    );
}