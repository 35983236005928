import React, { useEffect, useState } from 'react';
import 'notyf/notyf.min.css';
import './App.css';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { Layout, Login, Home, Orders, Users, Clients, OrdersTesting, Finance, Invoice, HistoryClients, Marketer } from './pages';

import './lib/i18n';
import { useTranslation } from 'react-i18next';
import { DirectionProvider, useDirection } from '@mantine/core';

import Cookies from 'universal-cookie';

const cookies = new Cookies(null, { path: '/' });
function App() {
    const { i18n } = useTranslation();
    const { toggleDirection, dir, setDirection } = useDirection();
    const [role, setRole] = useState("");

    useEffect(() => {
        if(cookies.get('lang')) {
            setDirection(i18n.dir(cookies.get('lang')))
            i18n.changeLanguage(cookies.get('lang'));
        } else {
            setDirection(i18n.dir("ar"))
            i18n.changeLanguage("ar");
        }

        if (i18n.dir() === "rtl") {
            if (document) {
                //@ts-ignore
                document.querySelector("html").setAttribute("dir", "rtl")
            }
        }
    }, [cookies.get('lang')])
  
    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])
  
    return (
        <DirectionProvider i18nIsDynamicList={true} initialDirection={i18n.dir()}>
            <Routes>
                <Route index path="/" element={<Login />} />
                <Route index path="/invoice" element={<Invoice />} />
                
                <Route path="/dashboard" element={<Layout />}>
                    
                    <Route index element={<Home />} />
                    
                    <Route path='orders' element={<Orders />} />
                    <Route path='orders-testing' element={<OrdersTesting />} />

                    {["admin", "responsible", "marketer", "developre"].includes(role)
                        ? <Route path='clients' element={<Clients />} />
                        : null
                    }

                    {["admin", "responsible"].includes(role)
                        ? <Route path='finance' element={<Finance/>} />
                        : null
                    }

                    {["admin"].includes(role)
                        ? <Route path='users' element={<Users/>} />
                        : null
                    }
                    {["admin"].includes(role)
                        ? <Route path='history-clients' element={<HistoryClients/>} />
                        : null
                    }
                    <Route path='marketer/:id' element={<Marketer/>} />
                </Route>
                <Route path="*" element={<h1>404</h1>} />
            </Routes>
        </DirectionProvider>
    );
}

export default App;
