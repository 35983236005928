import {Alert, Box, Button, Grid, Group, Text} from "@mantine/core";
import {IconCheck, IconX} from "@tabler/icons-react";
import Modal, { Props as ModalProps } from "./Modal";
import { client as clientAxios } from "../../lib/axiosClient";
import {Notyf} from "notyf";
import { useEffect, useState } from "react";
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

const cookies = new Cookies(null, { path: '/' });
const {Col} = Grid

type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
} & ModalProps;

export const SendMailToClient = ({data, ...props}: Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState<any>(null);

    useEffect(() => {
        if (props.opened && data !== null) {
            setClient(data)
        }
    }, [data, props.opened])

    const onFormSubmit = () => {
        setLoading(true)

        clientAxios.get(`/sendMailToClient/${data?._id}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            console.log(data);
            
            notifications.show({ message: "تم ارسال البريد الالكتروني الى العميل", color: '#fff' });
            setLoading(false)
            closeModal()
        })
        .catch((error) => {
            console.log(error);
            notifications.show({ message: "فشل ارسال البريد الالكتروني الى العميل", color: '#fff' });
            setLoading(false)
            closeModal()
        });
    };
    
    const closeModal = () => {
        props.onClose();
        setLoading(false)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading} size="lg"
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button variant="outline" color="gray" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'blue'} rightSection={<IconCheck size={15} />} onClick={onFormSubmit}>ارسال</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={({ colors }) => ({padding: 20})}>
                <Grid gutter={20}>
                    <Col span={12} >
                        <Text size="md">
                            عنوان البريد:
                            <Text ms={5} span fw={700}>معلومات الدخول الى متجرك</Text>
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text size="md" mb={10}>
                            محتوى البريد:
                        </Text>
                        <table style={{width: "100%"}}>
                            <tbody>
                                <tr>
                                    <td style={{width: "40%", border: "1px solid #000", padding: "5px 10px", textAlign: "center", background: "#fcd008"}}>رابط لوحة التحكم</td>
                                    <td style={{border: "1px solid #000", padding: "5px 10px", textAlign: "center"}}>{client?.adminUrl}</td>
                                </tr>
                                <tr>
                                    <td style={{width: "40%", border: "1px solid #000", padding: "5px 10px", textAlign: "center", background: "#fcd008"}}>رابط المتجر</td>
                                    <td style={{border: "1px solid #000", padding: "5px 10px", textAlign: "center"}}>{client?.storeUrl}</td>
                                </tr>
                                <tr>
                                    <td style={{width: "40%", border: "1px solid #000", padding: "5px 10px", textAlign: "center", background: "#fcd008"}}>البريد الالكتروني</td>
                                    <td style={{border: "1px solid #000", padding: "5px 10px", textAlign: "center"}}>{client?.userName}</td>
                                </tr>
                                <tr>
                                    <td style={{width: "40%", border: "1px solid #000", padding: "5px 10px", textAlign: "center", background: "#fcd008"}}>كلمة المرور</td>
                                    <td style={{border: "1px solid #000", padding: "5px 10px", textAlign: "center"}}>{client?.password}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Grid>
            </Box>
        </Modal>
    );
};