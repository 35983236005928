import {ActionIcon, Alert, Autocomplete, Box, Button, CheckIcon, CloseButton, Divider, Grid, Group, Image, List, NumberInput, PasswordInput, Radio, Select, Stack, Stepper, Switch, Text, TextInput, Textarea, Tooltip, rem} from "@mantine/core";
import {IconCheck, IconCloudUpload, IconMicrophone, IconMicrophone2, IconPlayerStopFilled, IconTrash, IconUpload, IconX} from "@tabler/icons-react";
import Modal, { Props as ModalProps } from "./Modal";
import { client } from "../../lib/axiosClient";
import { Notyf } from "notyf";
import { useEffect, useState } from "react";
import { notifications } from "@mantine/notifications";
// import {Notyf} from "notyf";

import Cookies from "universal-cookie";
import { useForm } from "@mantine/form";
import { zodResolver } from 'mantine-form-zod-resolver';
import { z } from 'zod';
import Wilayas from './../../helper/wilayas.json';
import Communes from './../../helper/communes.json';
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { RichText } from "../Custom";

import { ReactMediaRecorder, useReactMediaRecorder,  } from 'react-media-recorder';

const cookies = new Cookies(null, { path: '/' });
const {Col} = Grid

type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
    status: "new" | "testing" | "all"
} & ModalProps;

const dataStatus = {
    "new": [
        {label: "تاكيد", value: "testing"},
        {label: "تاكيد مؤقت بدون تجريب", value: "testing-confirmed"},
        {label: "المحاولة الاولى", value: "new-testing"},
        {label: "المحاولة الثانية", value: "new-testing-2"},
        {label: "المحاولة الثالثة", value: "new-testing-3"},
        {label: "مؤجل", value: "new-delayed"},
        {label: "لم يرد", value: "new-not-answer"},
        {label: "رفض", value: "new-rejected"}
    ],
    "testing": [
        {label: "تاكيد نهائي ودفع", value: "payment"},
        {label: "تاكيد مؤقت", value: "testing-confirmed"},
        {label: "المعاينة الاولى", value: "testing-attempted"},
        {label: "المعاينة الثانية", value: "testing-attempted-2"},
        {label: "المعاينة الثالثة", value: "testing-attempted-3"},
        {label: "مؤجل", value: "testing-delayed"},
        {label: "لم يرد", value: "testing-not-answer"},
        {label: "رفض", value: "testing-rejected"},
    ],
    "all": [
        {label: "تاكيد", value: "testing"},
        {label: "المحاولة الاولى", value: "new-testing"},
        {label: "المحاولة الثانية", value: "new-testing-2"},
        {label: "المحاولة الثالثة", value: "new-testing-3"},
        {label: "مؤجل (طلب جديد)", value: "new-delayed"},
        {label: "لم يرد (طلب جديد)", value: "new-not-answer"},
        {label: "رفض (طلب جديد)", value: "new-rejected"},

        {label: "تاكيد نهائي ودفع", value: "payment"},
        {label: "تاكيد مؤقت", value: "testing-confirmed"},
        {label: "المعاينة الاولى", value: "testing-attempted"},
        {label: "المعاينة الثانية", value: "testing-attempted-2"},
        {label: "المعاينة الثالثة", value: "testing-attempted-3"},
        {label: "مؤجل (تجريب)", value: "testing-delayed"},
        {label: "لم يرد (تجريب)", value: "testing-not-answer"},
        {label: "رفض (تجريب)", value: "testing-rejected"},
    ]
}

export const ChangeStatusOrder = ({data, status, ...props}: Props) => {
    const { t } = useTranslation();
    const { status: statusRecorder, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } = useReactMediaRecorder({ audio: true, video: false });
    const {onSubmit, reset, getInputProps, setValues, values, errors} = useForm({
        initialValues: { 
            status: "", note: "", price: 15000, methodPay: "", version: "basic",
            storeUrl: "",
            externalHost: {
                url: "",
                userName: "",
                password: ""
            }
        }
    });
    const [loading, setLoading] = useState(false);
    const [proofFile, setProofFiles] = useState<File[]>([]);
    let [description, setDescription] = useState("");
    const [recordings, setRecordings] = useState<Blob[]>([]);

    useEffect(() => {
        if (values.version) {
            setValues({
                price: values.version === "basic" ? 15000 : values.version === "pro" ? 21000 : 15000
            })
        }
    }, [values.version])

    useEffect(() => {
        if (statusRecorder === 'stopped' && mediaBlobUrl) {
            handleSaveRecording()
        }
    }, [statusRecorder, mediaBlobUrl, clearBlobUrl]);

    const onFormSubmit = ({status: statusForm, note, price, methodPay, version, storeUrl, externalHost}: any) => {
        setLoading(true)
        const timeLineStatus = dataStatus[status].filter((item: any) => item?.value === statusForm)
        const labelStatus = timeLineStatus[0]?.label
        
        let dataForm = new FormData();
        dataForm.append('status', statusForm);
        dataForm.append('version', version);
        if (statusForm === "payment") {
            dataForm.append('purchaseInfo', JSON.stringify({'price': price, 'methodPay': methodPay}));
            dataForm.append('storeUrl', storeUrl);
            dataForm.append('externalHost', JSON.stringify(externalHost));
        }
        dataForm.append('contentTimeLine', JSON.stringify({"status": labelStatus, "note": description, userId: cookies.get("id")}));
        
        dataForm.append('proof', proofFile[0]);
        
        for (let index = 0; index < recordings.length; index++) {
            const element = recordings[index];
            dataForm.append('recordings', element);
        }

        client.put(`/change-status/${data?._id}`, dataForm, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: "تم اضافة الحالة الجديدة", color: '#fff' });
            setLoading(false)
            typeof props?.refetchData == "function" && props?.refetchData()
            closeModal()
        })
        .catch((error) => {
            notifications.show({ message: "فشل اضافة الحالة الجديدة", color: '#fff' });
            setLoading(false)
            closeModal()
        });
    };

    const handleSaveRecording = () => {
        if (mediaBlobUrl) {
          fetch(mediaBlobUrl)
            .then(response => response.blob())
            .then(blob => {
                setRecordings(prevRecordings => [...prevRecordings, blob]);
                clearBlobUrl();
            })
            .catch(error => {
                console.error('Error fetching the audio:', error);
            });
        }
    };

    const deleteRecording = (index: number) => {
        let newRecordings = recordings
        newRecordings.splice(index, 1)
        
        setRecordings([...newRecordings])
    };

    const closeModal = () => {
        props.onClose();
        setLoading(false)
        reset()
        setProofFiles([])
        setDescription("")
        setRecordings([])
    };
    
    return (
        <Modal
            {...props} onClose={closeModal} loading={false} size="xl"
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={({ colors }) => ({padding: 20})}>
                <form onSubmit={onSubmit(onFormSubmit)} id="submit_form">
                    <Grid gutter={20} justify="flex-start">
                        <Grid.Col span={{ base: 12, sm: 12, md: 12 }}>
                            <Select
                                label={"حالة الطلب"}
                                placeholder={"حالة الطلب"}
                                data={dataStatus[status]}
                                {...getInputProps('status')}
                                allowDeselect={false}
                            />
                        </Grid.Col>

                        {values.status === "payment"
                            ? <>
                                <Grid.Col span={6} >
                                    <NumberInput
                                        label={"قيمة الدفع"}
                                        placeholder={"قيمة الدفع"}
                                        withAsterisk
                                        {...getInputProps("price")}
                                    />
                                </Grid.Col>
                                <Grid.Col span={6} >
                                    <Select
                                        label={"طريقة الدفع"}
                                        placeholder={"طريقة الدفع"}
                                        data={[
                                            {label: "كاش", value: "cash"},
                                            {label: "بريدي موب", value: "baridimob"},
                                            {label: "حوالة", value: "transfer"},
                                            {label: "Wise", value: "wise"},
                                            {label: "Paypal", value: "paypal"},
                                            {label: "Paysera", value: "paysera"}
                                        ]}
                                        withAsterisk
                                        {...getInputProps("methodPay")}
                                    />
                                </Grid.Col>
                                <Grid.Col span={6} >
                                    <Select
                                        label={"الخطة"}
                                        placeholder={"الخطة"}
                                        data={[
                                            {label: "الخطة البسيطة", value: "basic"},
                                            {label: "الخطة الاحترافية", value: "pro"},
                                            {label: "الخطة المخصصة", value: "custom"}
                                        ]}
                                        withAsterisk
                                        allowDeselect={false}
                                        {...getInputProps("version")}
                                    />
                                </Grid.Col>

                                <Grid.Col span={6} >
                                    <TextInput
                                        label={"رابط المتجر"}
                                        placeholder={"رابط المتجر"}
                                        withAsterisk
                                        {...getInputProps("storeUrl")}
                                    />
                                </Grid.Col>
                                
                                <Grid.Col span={12} mt={20}>
                                    <Divider labelPosition="left" label={<Text size="sm">المعلومات الخارجية :</Text>} />
                                </Grid.Col>
                                <Grid.Col span={12} >
                                    <TextInput
                                        label={"الرابط"}
                                        placeholder={"الرابط"}
                                        {...getInputProps("externalHost.url")}
                                    />
                                </Grid.Col>
                                <Grid.Col span={6} >
                                    <TextInput
                                        label={"اسم المستخدم"}
                                        placeholder={"اسم المستخدم"}
                                        {...getInputProps("externalHost.userName")}
                                    />
                                </Grid.Col>
                                <Grid.Col span={6} >
                                    <PasswordInput
                                        label={"كلمة المرور"}
                                        placeholder={"كلمة المرور"}
                                        {...getInputProps("externalHost.password")}
                                    />
                                </Grid.Col>
                        
                                <Grid.Col span={12} >
                                    <label style={{marginBottom: 20, fontSize: 16}}>{"إثبات الدفع"}</label>
                                    <Dropzone
                                        onDrop={(files: any) => setProofFiles(files)}
                                        onReject={(files) => console.log('rejected files', files)}
                                        maxSize={10 * 1024 ** 2}
                                        accept={IMAGE_MIME_TYPE}
                                    >
                                        <Group justify="center" gap="xl" mih={100} style={{ pointerEvents: 'none' }}>
                                            <Dropzone.Accept>
                                                <IconUpload
                                                    style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                                                    stroke={1.5}
                                                />
                                            </Dropzone.Accept>
                                            <Dropzone.Reject>
                                                <IconX
                                                    style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
                                                    stroke={1.5}
                                                />
                                            </Dropzone.Reject>
                                            <Dropzone.Idle>
                                                <Stack align="center" justify="center" gap={2}>
                                                    <IconCloudUpload size={40} style={{ color: 'var(--mantine-color-gray-8)' }} stroke={1} />
                                                    <Text size="lg" mt={10}>{"اسحب الصورة هنا أو انقر لتحديد الصورة"}</Text>
                                                    <Text size="sm" c="dimmed">{"قم بإرفاق الصورة ويجب ألا يتجاوز حجمها 5 ميجابايت"}</Text>
                                                </Stack>
                                            </Dropzone.Idle>
                                        </Group>
                                    </Dropzone>
                                </Grid.Col>
                            </>
                            : null
                        }
                        <Grid.Col span={{ base: 12, sm: 12, md: 12 }}>
                            <Text size="sm">{"ملاحظة"}</Text>
                            <RichText
                                content={description}
                                setContent={setDescription}
                            />
                        </Grid.Col>
                        
                        <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
                            <Stack>
                                <Group gap={5}>
                                    <Text size="sm" mb={10}>{"ملاحظات صوتية : "}</Text>
                                    {statusRecorder == "recording"
                                        ? <Tooltip label="توقيف وحفظ">
                                            <ActionIcon onClick={stopRecording} type="button" variant="transparent">
                                                <IconPlayerStopFilled size={16} color="red" />
                                            </ActionIcon>
                                        </Tooltip>
                                        : <Tooltip label="تسجيل">
                                            <ActionIcon onClick={startRecording} type="button" variant="transparent">
                                                <IconMicrophone size={16} />
                                            </ActionIcon>
                                        </Tooltip>
                                    }
                                </Group>
                                <List listStyleType="none" bg={"#fff"} p={20} display={recordings.length > 0 ? "block" : "none"}>
                                    {recordings.map((blob, index) => (
                                        <List.Item key={index}>
                                            <Group>
                                                <audio src={URL.createObjectURL(blob)} controls />
                                                <CloseButton
                                                    variant="transparent"
                                                    icon={<IconTrash color="red" size={20} stroke={1.5} />}
                                                    onClick={() => deleteRecording(index)}
                                                />
                                            </Group>
                                        </List.Item>
                                    ))}
                                </List>
                            </Stack>
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};