import React, { useEffect, useState } from 'react';
import { OrdersTestingTabel } from '../../componants/Tables';
import { AddProject, ChangeStatusOrder, DeleteClient, UpdateClient, UpdateClientStatus, UpdateClientTestingDate, UpdateConfermer, UpdateInfoClientOrder, UpdatePurchaseInformation } from '../../componants/Modal';
import { HeadPage } from '../../componants';
import { Group, SegmentedControl, TextInput } from '@mantine/core';
import { IconLock, IconSearch } from '@tabler/icons-react';
import classes from './../../styles/Product.module.css';
import { searchSortedData, sortedData } from '../../lib/sort';
import Cookies from 'universal-cookie';
import { useClients, useSearchOrders } from '../../api';
import { ShowClientDrawer } from '../../componants/Drawer';
import { useTranslation } from 'react-i18next';
import { AddClient } from '../../componants/Modal/AddClient';

const cookies = new Cookies(null, { path: '/' });

export function OrdersTesting () {
    const { t } = useTranslation();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openEditConfermerModal, setOpenEditConfermerModal] = useState(false);
    const [openEditTestingDateModal, setOpenEditTestingDateModal] = useState(false);
    const [openEditStatusModal, setOpenEditStatusModal] = useState(false);
    const [openShowModal, setOpenShowModal] = useState(false);
    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);

    const [selectedData, setSelectedData] = useState<any>(null);
    const [search, setSearch] = useState('');

    const [orders, setOrders] = useState<any[]>([]);
    const [ordersSorted, setOrdersSorted] = useState<any[]>([]);
    const [status, setStatus] = useState("testing");
    

    const {loading, error, data: dataOrders, refetch} = useClients({
        status,
        confirmedID: cookies.get('role') === "confirmed" ? cookies.get('id') || "" : ""
    })
    const [role, setRole] = useState("");

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])

    useEffect(() => {
        if (dataOrders) {
            setOrders(dataOrders)
            const newData = sortedData(dataOrders)
            setOrdersSorted(newData)
        }
    }, [dataOrders])


    useEffect(() => {
        if (search !== "") {
            const newData = searchSortedData(orders, ["name", "phone", "email"], search)
            setOrdersSorted(newData)
        } else {
            const newData = sortedData(orders)
            setOrdersSorted(newData)
        }
    }, [search])

    
    return (
        <>
            <HeadPage
                page={"العملاء قيد التجريب"}
                links={[
                    { title: "لوحة التحكم", href: '/dashboard' },
                    { title: "قائمة العملاء قيد التجريب", href: '' }
                ]}
                labelExport={t('ordersPage.labelExport')}
                hiddenExport={true}
                onCreate={() => console.log()}
                onExport={() => console.log()}
            />

            <Group justify='space-between' align='flex-end' mb={20}>
                <SegmentedControl
                    withItemsBorders={false} 
                    value={status}
                    onChange={setStatus}
                    data={[
                        {label: "قيد التجريب", value: "testing"},
                        {label: "المعاينة الاولى", value: "testing-attempted"},
                        {label: "المعاينة الثانية", value: "testing-attempted-2"},
                        {label: "المعاينة الثالثة", value: "testing-attempted-3"},
                        {label: "مؤجل", value: "testing-delayed"},
                        {label: "لم يرد", value: "testing-not-answer"},
                        {label: "مرفوض", value: "testing-rejected"},
                        {label: "مؤكد مؤقت", value: "testing-confirmed"}
                    ]}
                    styles={{
                        root: {
                            border: "1px solid #E0E2E7",
                            background: "#fff",
                            height: 40,
                            alignItems: "center",
                            overflowX: 'auto'
                        },
                        indicator: {
                            background: "#F2F7FB", display: "none",
                            height: 30, minHeight: 30, maxHeight: 30,
                            boxShadow: "none"
                        }
                    }}
                    classNames={{
                        control: classes.control
                    }}
                />
                
                <TextInput
                    leftSectionPointerEvents="none"
                    leftSection={<IconSearch size={18} />}
                    placeholder={t('ordersPage.placeholder')}
                    styles={{
                        input: {height: 40}
                    }}
                    value={search}
                    onChange={(event) => setSearch(event.currentTarget.value)}
                />
            </Group>

            <OrdersTestingTabel
                data={ordersSorted}
                setOpenDeleteModal={setOpenDeleteModal}
                setOpenEditModal={setOpenEditModal}
                setOpenShowModal={setOpenShowModal}
                setOpenChangeStatusModal={setOpenChangeStatusModal}
                setOpenEditTestingDateModal={setOpenEditTestingDateModal}
                setOpenEditStatusModal={setOpenEditStatusModal}
                setOpenEditConfermerModal={setOpenEditConfermerModal}
                setSelectedData={setSelectedData}
                loading={loading}
            />

            <UpdateClient title={"تعديل العميل"}  data={selectedData} refetchData={refetch} opened={openEditModal} onClose={() => setOpenEditModal(false)} />
            <DeleteClient data={selectedData} refetchData={refetch} opened={openDeleteModal} onClose={() => setOpenDeleteModal(false)} />
            <ChangeStatusOrder data={selectedData} status='testing' refetchData={refetch} opened={openChangeStatusModal} onClose={() => setOpenChangeStatusModal(false)} />
            <UpdateClientTestingDate title={"تجديد فترت تجريب العميل"}  data={selectedData} refetchData={refetch} opened={openEditTestingDateModal} onClose={() => setOpenEditTestingDateModal(false)} />
            <UpdateClientStatus title={"تغيير حالة العميل مباشرة"}  data={selectedData} refetchData={refetch} opened={openEditStatusModal} onClose={() => setOpenEditStatusModal(false)} />
            <ShowClientDrawer title={"تفاصيل العميل"} data={selectedData} opened={openShowModal} onClose={() => setOpenShowModal(false)} />

            <UpdateConfermer title={"تغيير موكد الطلبات"} refetchData={refetch} data={selectedData} opened={openEditConfermerModal} onClose={() => setOpenEditConfermerModal(false)} />
        </>
    );
}