import React, { useEffect, useState } from 'react';
import { FinanceTabel } from '../../componants/Tables';
import { AddInvoice, DeleteInvoice, ShowIvoiceSites, UpdateInvoice } from '../../componants/Modal';
import { HeadPage } from '../../componants';
import { Container, Group, SegmentedControl, Stack, Text, TextInput } from '@mantine/core';
import { IconLock, IconSearch } from '@tabler/icons-react';
import classes from './../../styles/Product.module.css';
import { searchSortedData, sortedData } from '../../lib/sort';
import Cookies from 'universal-cookie';
import { useFinances } from '../../api';
import { useTranslation } from 'react-i18next';

const cookies = new Cookies(null, { path: '/' });

export function Finance () {
    const { t } = useTranslation();
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openShowModal, setOpenShowModal] = useState(false);

    const [selectedData, setSelectedData] = useState<any>(null);

    const [search, setSearch] = useState('');

    const [orders, setOrders] = useState<any[]>([]);
    const [ordersSorted, setOrdersSorted] = useState<any[]>([]);
    const [version, setVersion] = useState("basic");

    const {loading, error, data: dataOrders, refetch} = useFinances()
    const [role, setRole] = useState("");

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])

    useEffect(() => {
        if (dataOrders) {
            setOrders(dataOrders)
            const newData = sortedData(dataOrders)
            setOrdersSorted(newData)
        }
    }, [dataOrders])

    useEffect(() => {
        if (search !== "") {
            const newData = searchSortedData(orders, ["name", "phone", "email"], search)
            setOrdersSorted(newData)
        } else {
            const newData = sortedData(orders)
            setOrdersSorted(newData)
        }
    }, [search])

    if (!["admin", "responsible"].includes(role)) {
        return (
            <Container>
                <Stack align='center' justify='center' h={"calc(100vh - 130px)"}>
                    <IconLock size={45} strokeWidth={1.5} />
                    <Text>ليس لديك صلحيات للوصول لهذه الصفحة</Text>
                </Stack>
            </Container>
        )
    }

    return (
        <>
            <HeadPage
                page={"المالية"}
                links={[
                    { title: "لوحة التحكم", href: '/dashboard' },
                    { title: "قائمة الفواتير", href: '' }
                ]}
                labelExport={t('ordersPage.labelExport')}
                labelCreate='اضافة فاتورة'
                hiddenExport={true}
                onCreate={() => setOpenAddModal(true)}
                onExport={() => console.log()}
            />

            {/* <Group justify='space-between' align='flex-end' mb={20}>
                <SegmentedControl
                    withItemsBorders={false} 
                    value={version}
                    onChange={setVersion}
                    data={[
                        {label: "الخطة البسيطة", value: "basic"},
                        {label: "الخطة الاحترافية", value: "pro"},
                        {label: "الخطة المخصصة", value: "custom"}
                        
                    ]}
                    styles={{
                        root: {
                            border: "1px solid #E0E2E7",
                            background: "#fff",
                            height: 40,
                            alignItems: "center"
                        },
                        indicator: {
                            background: "#F2F7FB",
                            height: 30, minHeight: 30, maxHeight: 30,
                            boxShadow: "none"
                        }
                    }}
                    classNames={{
                        control: classes.control
                    }}
                />
                
                <TextInput
                    leftSectionPointerEvents="none"
                    leftSection={<IconSearch size={18} />}
                    placeholder={t('ordersPage.placeholder')}
                    styles={{
                        input: {height: 40}
                    }}
                    value={search}
                    onChange={(event) => setSearch(event.currentTarget.value)}
                />
            </Group> */}

            <FinanceTabel
                data={ordersSorted}
                setOpenDeleteModal={setOpenDeleteModal}
                setOpenEditModal={setOpenEditModal}
                setOpenShowModal={setOpenShowModal}
                setSelectedData={setSelectedData}
                loading={loading}
            />

            <AddInvoice title={"إضافة فاتورة "} refetchData={refetch} data={selectedData} opened={openAddModal} onClose={() => setOpenAddModal(false)} />
            {/* <UpdateInvoice title={"تعديل الفاتورة"}  data={selectedData} refetchData={refetch} opened={openEditModal} onClose={() => setOpenEditModal(false)} /> */}
            <DeleteInvoice data={selectedData} refetchData={refetch} opened={openDeleteModal} onClose={() => setOpenDeleteModal(false)} />
            <ShowIvoiceSites data={selectedData} opened={openShowModal} onClose={() => setOpenShowModal(false)} />
        </>
    );
}