import { Box, Button, Grid, Group, NumberInput, Select, TagsInput, Textarea } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { client } from "../../lib/axiosClient";
import { IconCheck, IconX } from "@tabler/icons-react";
import { z } from 'zod';
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { useClients } from "../../api";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

export const UpdateInvoice = (props: Props) => {
    const { t } = useTranslation();
    
    const [loading, setLoading] = useState(false);
    const [openShowModal, setOpenShowModal] = useState(false);
    const [allSites, setAllSites] = useState<{ label: string, value: string, price: number, version: string }[]>([]);
    const [selectedSites, setSelectedSites] = useState<string[]>([]);
    const [totalPriceSites, setTotalPriceSites] = useState<number>(0);
    const {loading: loadingOrders, error, data: dataSits, refetch} = useClients({ status: "payment" })
    const {onSubmit, reset, getInputProps, setValues, values} = useForm({
        initialValues: { price: 0, note: "", type: ""},
    });

    useEffect(() => {
        if (dataSits && dataSits?.length >= 0) {
            let newData: { label: string, value: string, price: number, version: string }[] = []
            for (let i = 0; i < dataSits.length; i++) {
                const element = dataSits[i];
                if (element?.storeUrl && element?.storeUrl !== "") {
                    newData.push({
                        label: element?.storeUrl?.split("://")?.[1],
                        value: element?._id,
                        version: element?.version,
                        price: element?.purchaseInfo?.price
                    })
                }
            }
            setAllSites(newData)
        }
    }, [dataSits])

    useEffect(() => {
        if (props.opened && props.data !== null) {
            const invoice = props.data
            setValues({
                price: invoice?.price,
                note: invoice?.note,
                type: invoice?.type
            })
        }
    }, [props.opened, props?.data])

    const onSubmitForm = ({price, note, type}: any) => {
        setLoading(true)

        const sites = [{ site: "", version: "", price: 0}]
        
        client.post(`/invoices`, {
            note,
            price,
            sites: type === "expenses" ? [] : sites,
            type
        }, {
            headers: {'Accept': 'application/json', 'Authorization': cookies.get('token') || ""}
        })
        .then(({data}) => {
            notifications.show({ message: "تم اضافة الفاتورة", color: '#fff' });
            console.log(data);
            setLoading(false)
            typeof props.refetchData == "function" && props.refetchData()
            closeModal()
        })
        .catch(({response}) => {
            notifications.show({ message: "فشل اضافة الفاتورة", color: '#fff' });
            setLoading(false)
        });
    }

    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
        setAllSites([])
        setSelectedSites([])
        setTotalPriceSites(0)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={{padding: 20}}>
                <form onSubmit={onSubmit(onSubmitForm)} id="submit_form">
                    <Grid gutter={20} justify="flex-start">
                        <Grid.Col span={6} >
                            <Select
                                label={"نوع الفاتورة"}
                                placeholder={"نوع الفاتورة"}
                                data={[
                                    {label: "مصروفات", value: "expenses"},
                                    {label: "استلام ثمن المواقع", value: "receiving"},
                                ]}
                                withAsterisk
                                allowDeselect={false}
                                {...getInputProps("type")}
                            />
                        </Grid.Col>
                        <Grid.Col span={6} >
                            <NumberInput
                                label={"السعر"}
                                placeholder={"السعر"}
                                withAsterisk
                                {...getInputProps("price")}
                            />
                        </Grid.Col>

                        {values.type === "receiving"
                            ? <Grid.Col span={12} >
                                <TagsInput
                                    label={"المواقع"}
                                    placeholder={"اختر المواقع المطلوبة"}
                                    data={[
                                        { group: 'Frontend', items: ['React', 'Angular'] },
                                        { group: 'Backend', items: ['Express', 'Django'] },
                                    ]}
                                />
                            </Grid.Col>
                            : null
                        }
                        

                        <Grid.Col span={12} >
                            <Textarea
                                label={"ملاحظة"}
                                placeholder={"ملاحظة"}
                                {...getInputProps("note")}
                            />
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};