import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CheckIcon, Combobox, Group, Input, InputBase, Pill, PillsInput, ScrollArea, Stack, Text, useCombobox } from '@mantine/core';

interface Site {
    value: string;
    price: number;
    label: string;
    version: string;
}

interface Props {
    value: string[];
    setValue: Dispatch<SetStateAction<string[]>>;
    setTotalPriceSites: Dispatch<SetStateAction<number>>;
    sites: Site[];
}

export function ComboboxSites({value, setValue, setTotalPriceSites, sites}: Props) {
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
    });
    const [selectedOption, setSelectedOption] = useState<Site[]>([]);

    const handleValueSelect = (val: string) => {
        setValue((current) =>
            current.includes(val) ? current.filter((v) => v !== val) : [...current, val]
        )
    };

    useEffect(() => {
        setSelectedOption(sites.filter((item) => value.includes(item.value)))
    }, [value])

    useEffect(() => {
        setTotalPriceSites(selectedOption.reduce((previousValue: number, currentValue: Site) => previousValue + currentValue.price * 0.4, 0))
    }, [selectedOption])

    const handleValueRemove = (val: string) => setValue((current) => current.filter((v) => v !== val));
    
    const values = selectedOption.map((item) => (
        <Pill key={item.value} withRemoveButton onRemove={() => handleValueRemove(item.value)}>
            {item.label}
        </Pill>
    ));
    
    const options = sites.filter((site) => !value.includes(site.value)).map((item) => (
        <Combobox.Option 
            value={item.value} key={item.value}
            active={value.includes(item.value)}
            bg={value.includes(item.value) ? "#efefef" : "#fff"}
        >
            <Group w={"100%"} justify='flex-start' align='center'>
                <Stack gap={0} flex={1}>
                    <Text fz={14} fw={700}>{item.label}</Text>
                    <Group justify='space-between' align='center'>
                        <Text fz={12}>{item.version}</Text>
                        <Text fz={14}>{item.price} دج</Text>
                    </Group>
                </Stack>
                {value.includes(item.value) ? <CheckIcon size={12} /> : null}
            </Group>
        </Combobox.Option>
    ));

    return (
        <Combobox
            store={combobox}
            withinPortal={false}
            onOptionSubmit={handleValueSelect}
        >
            <Combobox.Target>
                <PillsInput 
                    onClick={() => combobox.openDropdown()}
                    label="المواقع"
                >
                    <Pill.Group>
                        {values}
                    </Pill.Group>
                </PillsInput>
            </Combobox.Target>

            <Combobox.Dropdown style={{zIndex: 1002}}>
                <Combobox.Options mah={200}>
                    {options.length > 0 
                        ? <ScrollArea.Autosize type="scroll" mah={180}>{options}</ScrollArea.Autosize>
                        : <Group w={"100%"} justify='center' align='center' py={10}>
                            <Text fz={14} c={'red'}>{"لا يوجد بيانات او تم تحديدها جميعا"}</Text>
                        </Group>
                    }
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
}