import {Alert, Box, Button, Grid, Group, Text} from "@mantine/core";
import {IconCheck, IconX} from "@tabler/icons-react";
import Modal, { Props as ModalProps } from "./Modal";
import { client } from "../../lib/axiosClient";
import {Notyf} from "notyf";
import { useEffect, useState } from "react";
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

const cookies = new Cookies(null, { path: '/' });
const {Col} = Grid

type Props = {
    setSelectedData?: (id: string) => void;
    setClient: (data: any) => void;
    data?: any;
    item?: string;
    refetchData?: () => void;
} & ModalProps;

export const DeleteItemFromTimeLine = ({data, item, setClient, ...props}: Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [newTimeLine, setNewTimeLine] = useState<any[]>([]);

    useEffect(() => {
        if (props.opened && data !== null && item !== null && item !== "") {
            let timeLine = []
            for (let i = 0; i < data?.timeLine?.length; i++) {
                const element = data?.timeLine[i];
                if (element._id !== item) {
                    timeLine.push(element)
                }
            }
            setNewTimeLine(timeLine)
        }
    }, [item, props.opened])
    
    
    const onFormSubmit = () => {
        setLoading(true)
        if (data?.timeLine && item && item !== "" && newTimeLine.length === data?.timeLine?.length - 1) {
            client.put(`/clients/${data?._id}`, { timeLine: newTimeLine }, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': cookies.get('token') || ""
                }
            })
            .then(({data: dataUpdate}) => {
                setClient({...data, timeLine: newTimeLine})
                notifications.show({ message: "تم حذف الحالة", color: '#fff' });
                setLoading(false)
                typeof props?.refetchData == "function" && props?.refetchData()
                closeModal()
            })
            .catch((error) => {
                notifications.show({ message: "فشل حذف الحالة", color: '#fff' });
                setLoading(false)
                closeModal()
            });
        } else {
            notifications.show({ message: "فشل حذف الحالة", color: '#fff' });
        }
    };

    const closeModal = () => {
        props.onClose();
        setLoading(false)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading} size="lg"
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button variant="outline" color="gray" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'red'} rightSection={<IconCheck size={15} />} onClick={onFormSubmit}>حذف</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={({ colors }) => ({padding: 20})}>
                <Grid gutter={20}>
                    <Col span={12} >
                        <Alert variant="" color="red">
                            {"هل انت متاكد من حذف الحالة؟"}
                        </Alert>
                    </Col>
                </Grid>
            </Box>
        </Modal>
    );
};