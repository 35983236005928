import {ActionIcon, Badge, Divider, Group, HoverCard, Image, List, Menu, Skeleton, Stack, Text, Tooltip, rem} from '@mantine/core';
import {IconDotsVertical, IconEdit, IconTrash, IconEye, IconDashboard, IconCloudUpload, IconCashBanknote} from '@tabler/icons-react';
import DataTable, {TableStyles} from 'react-data-table-component';
import dayjs from "dayjs";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

const customStyles: TableStyles = {
    table: {
        style: {
            minHeight: 380,
            border: "2px solid #E0E2E7",
            borderRadius: "8px"
        },
    },
    headRow: {
        style: {
            borderRadius: "8px 8px 0 0",
            ":nth-child(1)": {
                borderRadius: "0 8px 0 0"
            },
            ":nth-last-child(1)": {
                borderRadius: "8px 0 0 0"
            }
        },
    },
    headCells: {
        style: {
            fontWeight: 800,
            fontSize: 14,
            background: "#F9F9FC",
            height: "56px",
            ":nth-child(1)": {
                background: "#F9F9FC",
            }
        }
    },
    cells: {
        style: {
            height: "60px"
        }
    }
};

type Props = {
    setSelectedData: (data: any) => void;

    setOpenEditModal: (status: boolean) => void;
    setOpenDeleteModal: (status: boolean) => void;
    setOpenShowModal: (status: boolean) => void;
    data?: any;
    loading?: boolean;
};

const cookies = new Cookies(null, { path: '/' });

export const FinanceTabel = ({data, loading, setSelectedData, setOpenEditModal, setOpenDeleteModal, setOpenShowModal}: Props) => {
    const { t } = useTranslation();
    const [role, setRole] = useState("");

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])

    const getLastTrace = (data: any) => {
        if (!data) return []
        const length = data.length
        return data[length-1]
    }

    const columns = [
        {name: "العنوان", selector: (row: any, index: number) => (
            row?.type === "expenses"
            ? "مصروفات"
            : "استلام ثمن المواقع"
        ), minWidth: "130px"},
        {name: "القيمة", selector: (row: any, index: number) => <Text size='sm' c={row?.type === "expenses" ? "red" : "green"}>{row?.price} دج</Text>, minWidth: "150px"},
        {name: "الملاحظة", selector: (row: any, index: number) => row?.note, minWidth: "270px"},
        {name: "تاريخ التسجيل", selector: (row: any) => dayjs(row?.createdAt).locale("ar").fromNow(), minWidth: "140px", sortable: true, sortFunction: (a: any, b: any) => a?.createdAt > b?.createdAt ? 1 : -1 },

        {name: "الخيارات", cell: (row: any) => (
            <Group wrap={"nowrap"} justify={"center"} w={"100%"} gap={5}>
                <ActionIcon
                    variant={"transparent"} color={"#667085"}
                    disabled={!(row?.sites && row?.sites?.length > 0)}
                    onClick={() => {
                        setSelectedData(row)
                        setOpenShowModal(true)
                    }}
                >
                    <IconEye size="1.125rem" />
                </ActionIcon>
                {["admin"].includes(role)
                    ? <Menu shadow="md" width={170}>
                        <Menu.Target>
                            <ActionIcon variant={"transparent"} color={"darck"}>
                                <IconDotsVertical size="1.125rem" />
                            </ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                            <Divider />
                            <Menu.Item
                                leftSection={<IconEdit size={14} />} disabled
                                onClick={() => {
                                    setSelectedData(row)
                                    setOpenEditModal(true)
                                }}
                            >{t('tables.orders.edit')}</Menu.Item>
                            <Menu.Item
                                leftSection={<IconTrash size={14} />}
                                onClick={() => {
                                    setSelectedData(row._id)
                                    setOpenDeleteModal(true)
                                }}
                            >{t('tables.orders.delete')}</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                    : null
                }
            </Group>
        ), allowOverflow: true, button: true, minWidth: '100px' }
    ];

    return (
        <DataTable
            // @ts-ignore
            columns={columns}
            data={data}
            customStyles={customStyles}
            highlightOnHover
            persistTableHead={true}
            progressPending={loading || false}
            progressComponent={<LoadingTable />}
            noDataComponent={
                <Stack align='center' justify='center' p={10}>
                    <Image src={"/emptyData.png"} mah={150} w={"auto"} />
                    <Text size='14px' c={"#667085"}>{t('tables.emptyLable')}</Text>
                </Stack>
            }

            pagination={true}
            paginationComponentOptions={{
                rowsPerPageText: t('tables.rangeSeparatorText'),
                rangeSeparatorText: t('tables.rangeSeparatorText')
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100, 200]}
            paginationPerPage={100}
        />
    );
};


const LoadingTable = () => {
    const numRows = [0,0,0];
    return (
        <Stack w={"100%"} p={0} m={0} gap={0}>
            {numRows.map((item, index) => (
                <RowSkeleton key={index}/>
            ))}
        </Stack>
    )
}

const RowSkeleton = () => {
    
    return (
        <Group w={"100%"} mih={48} align='stretch' gap={0} wrap='nowrap' style={{borderBottom: "2px solid #E0E2E7"}}>
            <Group h={60} miw={"250px"} px={16} justify='flex-start' align='center' gap={8} wrap='nowrap'>
                <Skeleton width={44} height={44} />
                <Stack justify='center' align='flex-start' gap={5}>
                    <Skeleton height={14} width={120} />
                    <Skeleton height={12} width={60} />
                </Stack>
            </Group>
            <Group h={60} miw={"170px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"120px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"110px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"110px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"140px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"140px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"140px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"100px"} px={16} justify='flex-start' align='center'>
                <Group wrap={"nowrap"} justify={"center"} w={"100%"} gap={2}>
                    <Skeleton height={18} width={18} />
                    <Skeleton height={18} width={18} />
                </Group>
            </Group>
        </Group>
    );
}