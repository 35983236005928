import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { Box, Container, Grid, Group, Image, Paper, SimpleGrid, Stack, Text, useMantineTheme } from '@mantine/core';
import { IconBox, IconCurrencyDollar, IconExternalLink, IconLock, IconShoppingBag } from '@tabler/icons-react';
import classes from './../../styles/StatsSegments.module.css';
import { useStatistics } from '../../api';
import { DonutChart } from '@mantine/charts';
import { useTranslation } from 'react-i18next';
import { OrdersStatisticsTabel } from '../../componants/Tables';
import { sortedData } from '../../lib/sort';
//@ts-ignore
import { Helmet } from 'react-helmet';
import { ChangeStatusOrder, DeleteClient, UpdateClient, UpdateClientStatus, UpdateConfermer } from '../../componants/Modal';
import { ShowClientDrawer } from '../../componants/Drawer';

const cookies = new Cookies(null, { path: '/' });
interface Statistics {
    new: {
        total: number,
        new: number,
        newTesting: number,
        newRejected: number,
        newTesting2: number,
        newTesting3: number,
        newDelayed: number,
        newNotAnswer: number,
    },
    testing: {
        total: number,
        testing: number,
        testingAttempted: number,
        testingRejected: number,
        testingConfirmed: number,
        testingAttempted2: number,
        testingAttempted3: number,
        testingDelayed: number,
        testingNotAnswer: number,
    },
    payment: {
        total: number,
        basic: number,
        pro: number,
        custom: number
    },
    allClientToday: any[],
    todayOrders: number,
    beforeDayOrders: number,
    weekOrders: number,
    monthOrders: number,
}
/*
new: {
    total: statusNew + statusNewTesting + statusNewRejected,
    new: statusNew,
    newTesting: statusNewTesting,
    newRejected: statusNewRejected
},
testing: {
    total: statusTesting + statusTestingAttempted + statusTestingRejected,
    testing: statusTesting,
    testingAttempted: statusTestingAttempted,
    testingRejected: statusTestingRejected
},
payment: {
    total: statusPayment,
    basic: basicTheme,
    pro: proTheme,
    custom: customTheme
}
*/
export function Home () {
    const { t } = useTranslation();
    const [statistics, setStatistics] = useState<Statistics | null>(null);
    
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openEditConfermerModal, setOpenEditConfermerModal] = useState(false);
    const [openShowModal, setOpenShowModal] = useState(false);
    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
    const [openEditStatusModal, setOpenEditStatusModal] = useState(false);

    const [selectedData, setSelectedData] = useState<any>(null);
    const theme = useMantineTheme();
    const {loading, error, data: dataStatistics, refetch} = useStatistics({
        confirmedID: cookies.get('role') === "confirmed" ? cookies.get('id') || "" : ""
    })
    const [role, setRole] = useState("");

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])
    
    useEffect(() => {
        if (dataStatistics) {
            //@ts-ignore
            setStatistics(dataStatistics)
        }
    }, [dataStatistics])
    
    if (["marketer"].includes(role)) {
        return (
            <Container>
                <Stack align='center' justify='center' h={"calc(100vh - 130px)"}>
                    <IconLock size={45} strokeWidth={1.5} />
                    <Text>ليس لديك صلحيات للوصول لهذه الصفحة</Text>
                </Stack>
            </Container>
        )
    }

    return (
        <Grid>
            <Grid.Col span={12}>
                <Box w={"100%"} h={190} bg={"#fff"} p={20}>
                    <Group justify='space-between' wrap='nowrap'>
                        <Stack justify='space-between' h={150}>
                            <Stack gap={15}>
                                <Text size='26px' fw={600} c={"dark.7"}>{t('homePage.title')} {cookies.get("name")} 👋</Text>
                                <Text size='sm' c={"gray.5"}>{t('homePage.description')}</Text>
                            </Stack>

                            {/* <Text 
                                size='16px' c={"#323232"}
                                component='a'
                                target='_blank'
                                href={process.env.REACT_APP_STORE_URL}
                            >
                                <IconExternalLink size={14} style={{marginLeft: 2}} />
                                {t('homePage.link')}
                            </Text> */}
                        </Stack>
                        <Image src={"/header.png"} height={150} width={"auto"} visibleFrom='md' />
                    </Group>
                </Box>
            </Grid.Col>

            <Grid.Col span={12} mb={10}>
                <Text fz="xl" fw={600}>{"احصائيات عامة"}</Text>
            </Grid.Col>

            <Grid.Col span={{base: 12, md: 4}}>
                <Paper withBorder p="md" radius="md">
                    <Group justify="space-between">
                        <Text fz="lg" fw={700}>{"عملاء جدد"}</Text>
                        <IconCurrencyDollar size="1.4rem" className={classes.icon} stroke={1.5} />
                    </Group>

                    <Text c="dimmed" fz="sm">
                        {"إجمالي العملاء جدد"} {statistics?.new?.total}
                    </Text>

                    <SimpleGrid bg={theme.colors.gray[0]} p={10} cols={{ base: 1 }} mt="xl" style={{justifyItems: 'center', gap: 0}}>
                        <DonutChart
                            withLabelsLine withLabels
                            data={[
                                { name: "طلب جديد", value: statistics?.new?.new || 0, color: 'teal' },
                                { name: "المحاولة الاولى", value: statistics?.new?.newTesting || 0, color: 'cyan' },
                                { name: "المحاولة الثانية", value: statistics?.new?.newTesting2 || 0, color: 'yellow' },
                                { name: "المحاولة الثالثة", value: statistics?.new?.newTesting3 || 0, color: 'pink' },
                                { name: "مؤجل", value: statistics?.new?.newDelayed || 0, color: 'violet' },
                                { name: "لم يرد", value: statistics?.new?.newNotAnswer || 0, color: 'gray' },
                                { name: "مرفوض", value: statistics?.new?.newRejected || 0, color: 'red' }
                            ]} 
                        />
                        <Group justify='center'>
                            <Group gap={2} align='center'>
                                <Box bg={'teal'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"طلب جديد"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'cyan'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"المحاولة الاولى"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'yellow'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"المحاولة الثانية"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'pink'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"المحاولة الثالثة"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'violet'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"مؤجل"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'gray'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"لم يرد"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'red'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"مرفوض"}</Text>
                            </Group>
                        </Group>
                    </SimpleGrid>
                </Paper>
            </Grid.Col>
            
            <Grid.Col span={{base: 12, md: 4}}>
                <Paper withBorder p="md" radius="md">
                    <Group justify="space-between">
                        <Text fz="lg" fw={700}>{"عملاء قيد التجريب"}</Text>
                        <IconShoppingBag size="1.4rem" className={classes.icon} stroke={1.5} />
                    </Group>

                    <Text c="dimmed" fz="sm">
                        {"إجمالي عدد العملاء قيد التجريب"} {statistics?.testing?.total || 0}
                    </Text>

                    <SimpleGrid bg={theme.colors.gray[0]} p={10} cols={{ base: 1 }} mt="xl" style={{justifyItems: 'center', gap: 0}}>
                        <DonutChart
                            withLabelsLine withLabels
                            data={[
                                { name: "قيد التجريب", value: statistics?.testing?.testing || 0, color: 'blue' },
                                { name: "المعاينة الاولى", value: statistics?.testing?.testingAttempted || 0, color: 'cyan' },
                                { name: "المعاينة الثانية", value: statistics?.testing?.testingAttempted2 || 0, color: 'yellow' },
                                { name: "المعاينة الثالثة", value: statistics?.testing?.testingAttempted3 || 0, color: 'pink' },
                                { name: "مؤجل", value: statistics?.testing?.testingDelayed || 0, color: 'violet' },
                                { name: "لم يرد", value: statistics?.testing?.testingNotAnswer || 0, color: 'gray' },
                                { name: "مرفوض", value: statistics?.testing?.testingRejected || 0, color: 'red' },
                                { name: "مؤكد مؤقت", value: statistics?.testing?.testingConfirmed || 0, color: 'teal' }
                            ]} 
                        />
                        <Group justify='center'>
                            <Group gap={2} align='center'>
                                <Box bg={'blue'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"قيد التجريب"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'cyan'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"المعاينة الاولى"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'yellow'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"المعاينة الثانية"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'pink'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"المعاينة الثالثة"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'violet'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"مؤجل"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'gray'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"لم يرد"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'red'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"مرفوض"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'teal'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"مؤكد مؤقت"}</Text>
                            </Group>
                        </Group>
                    </SimpleGrid>
                </Paper>
            </Grid.Col>

            <Grid.Col span={{base: 12, md: 4}}>
                <Paper withBorder p="md" radius="md">
                    <Group justify="space-between">
                        <Text fz="lg" fw={700}>{"عملاء"}</Text>
                        <IconBox size="1.4rem" className={classes.icon} stroke={1.5} />
                    </Group>

                    <Text c="dimmed" fz="sm">
                        {"إجمالي عدد العملاء النهائيين"} {statistics?.payment?.total}
                    </Text>

                    <SimpleGrid bg={theme.colors.gray[0]} p={10} cols={{ base: 1 }} mt="xl" style={{justifyItems: 'center', gap: 0}}>
                        <DonutChart
                            withLabelsLine withLabels
                            data={[
                                { name: "الخطة البسيطة", value: statistics?.payment?.basic || 0, color: 'blue' },
                                { name: "الخطة الاحترافية", value: statistics?.payment?.pro || 0, color: 'grape' },
                                { name: "الخطة المخصصة", value: statistics?.payment?.custom || 0, color: 'lime' }
                            ]}
                        />
                        <Group justify='center'>
                            <Group gap={2} align='center'>
                                <Box bg={'blue'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"الخطة البسيطة"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'grape'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"الخطة الاحترافية"}</Text>
                            </Group>
                            <Group gap={2} align='center'>
                                <Box bg={'lime'} w={14} h={14} style={{borderRadius: "50%"}} />
                                <Text size='sm'>{"الخطة المخصصة"}</Text>
                            </Group>
                        </Group>
                    </SimpleGrid>
                </Paper>
            </Grid.Col>


            <Grid.Col span={{base: 12, md: 12}}>
                <Grid>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.todayOrders || 0}</Text>
                            <Text size='md' ta={'center'}>طلبات اليوم</Text>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.beforeDayOrders || 0}</Text>
                            <Text size='md' ta={'center'}>طلبات امس</Text>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.weekOrders || 0}</Text>
                            <Text size='md' ta={'center'}>طلبات اخر 7 ايام</Text>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                        <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                            <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.monthOrders || 0}</Text>
                            <Text size='md' ta={'center'}>طلبات هذا الشهر</Text>
                        </Box>
                    </Grid.Col>
                </Grid>
            </Grid.Col>

            <Grid.Col span={12} mb={20}>
                <Text fz="xl" fw={600}>{"طلبات اخر 24 ساعة"}</Text>
            </Grid.Col>
            <OrdersStatisticsTabel
                //@ts-ignore
                data={sortedData(statistics?.allClientToday || [])}
                setSelectedData={setSelectedData}
                setOpenDeleteModal={setOpenDeleteModal}
                setOpenEditModal={setOpenEditModal}
                setOpenShowModal={setOpenShowModal}
                setOpenChangeStatusModal={setOpenChangeStatusModal}
                setOpenEditStatusModal={setOpenEditStatusModal}
                setOpenEditConfermerModal={setOpenEditConfermerModal}
            />


            <UpdateClient title={"تعديل العميل"}  data={selectedData} refetchData={refetch} opened={openEditModal} onClose={() => setOpenEditModal(false)} />
            <DeleteClient data={selectedData} refetchData={refetch} opened={openDeleteModal} onClose={() => setOpenDeleteModal(false)} />
            <ChangeStatusOrder data={selectedData} status='new' refetchData={refetch} opened={openChangeStatusModal} onClose={() => setOpenChangeStatusModal(false)} />
            <UpdateClientStatus title={"تغيير حالة العميل مباشرة"}  data={selectedData} refetchData={refetch} opened={openEditStatusModal} onClose={() => setOpenEditStatusModal(false)} />
            <ShowClientDrawer title={"تفاصيل العميل"} data={selectedData} opened={openShowModal} onClose={() => setOpenShowModal(false)} />

            <UpdateConfermer title={"تغيير موكد الطلبات"} refetchData={refetch} data={selectedData} opened={openEditConfermerModal} onClose={() => setOpenEditConfermerModal(false)} />
        </Grid>
    );
}