import { Box, Button, Checkbox, CloseButton, ColorInput, Divider, Grid, Group, Image, NumberInput, PasswordInput, Select, Switch, Tabs, Text, TextInput, Textarea, rem } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { client } from "../../lib/axiosClient";
import { IconCheck, IconPhoto, IconUpload, IconX, IconXboxX } from "@tabler/icons-react";
import { zodResolver } from 'mantine-form-zod-resolver';
import { z } from 'zod';
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import Wilayas from '../../helper/wilayas.json';
import { useStore } from "../../api";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    data?: any;
    refetchData?: () => void;
} & ModalProps;

export const UpdateInfoClientOrder = (props: Props) => {
    const { t } = useTranslation();
    const schema = z.object({
        userName: z.string({message: "اسم المستخدم اجباري"}).min(1, { message: "اسم المستخدم اجباري" }),
        password: z.string({message: "كلمة المرور اجباري"}).min(1, { message: "كلمة المرور اجباري" }),
        vpsIP: z.string({message: "IP السيرفر اجباري"}).min(1, { message: "IP السيرفر اجباري" }),
        adminUrl: z.string({message: "رابط لوحة التحكم اجباري"}).min(1, { message: "رابط لوحة التحكم اجباري" }),
        storeUrl: z.string({message: "رابط المتجر اجباري"}).min(1, { message: "رابط المتجر اجباري" }),
        apiUrl: z.string({message: "رابط API اجباري"}).min(1, { message: "رابط API اجباري" })
    });
    const [loading, setLoading] = useState(false);
    const {onSubmit, reset, getInputProps, setValues, values} = useForm({
        initialValues: {
            userName: "",
            password: "",
            vpsIP: "",
            adminUrl: "",
            storeUrl: "",
            apiUrl: "",
            externalUrl: "",
            externalUserName: "",
            externalPassword: "",
        },
        validate: zodResolver(schema),
        validateInputOnBlur: true,
        validateInputOnChange: true
    });

    useEffect(() => {
        if (props.opened && props.data !== null) {
            const client = props.data
            setValues({
                userName: client?.userName || "",
                password: client?.password || "",
                vpsIP: client?.vpsIP || "",
                adminUrl: client?.adminUrl || "",
                storeUrl: client?.storeUrl || "",
                apiUrl: client?.apiUrl || ""
            })
        }
    }, [props.opened, props?.data])

    const onSubmitForm = ({userName, password, vpsIP, adminUrl, storeUrl, apiUrl, externalUrl, externalUserName, externalPassword}: any) => {
        setLoading(true)
        
        client.put(`/clients/${props.data._id}`, {
            userName, password, vpsIP, adminUrl, storeUrl, apiUrl,
            externalHost: {
                url: externalUrl,
                userName: externalUserName,
                password: externalPassword
            }
        }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: "تم تعديل العميل", color: '#fff' });
            console.log(data);
            setLoading(false)
            typeof props.refetchData == "function" && props.refetchData()
            closeModal()
        })
        .catch(({response}) => {
            notifications.show({ message: "فشل تعديل العميل", color: '#fff' });
            setLoading(false)
        });
    }

    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={{padding: 20}}>
                <form onSubmit={onSubmit(onSubmitForm)} id="submit_form">
                    <Grid gutter={20} justify="flex-start">
                        <Grid.Col span={12} >
                            <Divider labelPosition="left" label={<Text size="sm">معلومات الخادم الداخلية :</Text>} />
                        </Grid.Col>
                        <Grid.Col span={6} >
                            <TextInput
                                label={"اسم المستخدم"}
                                placeholder={"اسم المستخدم"}
                                withAsterisk
                                {...getInputProps("userName")}
                            />
                        </Grid.Col>
                        <Grid.Col span={6} >
                            <PasswordInput
                                label={"كلمة المرور"}
                                placeholder={"كلمة المرور"}
                                withAsterisk
                                {...getInputProps("password")}
                            />
                        </Grid.Col>
                        <Grid.Col span={6} >
                            <TextInput
                                label={"VPS IP"}
                                placeholder={"VPS IP"}
                                withAsterisk
                                {...getInputProps("vpsIP")}
                            />
                        </Grid.Col>
                        <Grid.Col span={6} >
                            <TextInput
                                label={"رابط API"}
                                placeholder={"رابط API"}
                                withAsterisk
                                {...getInputProps("apiUrl")}
                            />
                        </Grid.Col>
                        <Grid.Col span={6} >
                            <TextInput
                                label={"رابط لوحة التحكم"}
                                placeholder={"رابط لوحة التحكم"}
                                withAsterisk
                                {...getInputProps("adminUrl")}
                            />
                        </Grid.Col>
                        <Grid.Col span={6} >
                            <TextInput
                                label={"رابط المتجر"}
                                placeholder={"رابط المتجر"}
                                withAsterisk
                                {...getInputProps("storeUrl")}
                            />
                        </Grid.Col>

                        <Grid.Col span={12} mt={20}>
                            <Divider labelPosition="left" label={<Text size="sm">المعلومات الخارجية :</Text>} />
                        </Grid.Col>
                        <Grid.Col span={12} >
                            <TextInput
                                label={"الرابط"}
                                placeholder={"الرابط"}
                                {...getInputProps("externalUrl")}
                            />
                        </Grid.Col>
                        <Grid.Col span={6} >
                            <TextInput
                                label={"اسم المستخدم"}
                                placeholder={"اسم المستخدم"}
                                {...getInputProps("externalUserName")}
                            />
                        </Grid.Col>
                        <Grid.Col span={6} >
                            <PasswordInput
                                label={"كلمة المرور"}
                                placeholder={"كلمة المرور"}
                                {...getInputProps("externalPassword")}
                            />
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};