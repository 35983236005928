import { Box, Button, Checkbox, CloseButton, ColorInput, Divider, Grid, Group, Image, NumberInput, PasswordInput, Select, Stack, Switch, Tabs, Text, TextInput, Textarea, rem } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { client } from "../../lib/axiosClient";
import { IconCheck, IconCloudUpload, IconPhoto, IconUpload, IconX, IconXboxX } from "@tabler/icons-react";
import { zodResolver } from 'mantine-form-zod-resolver';
import { z } from 'zod';
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import Wilayas from '../../helper/wilayas.json';
import { useStore } from "../../api";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    data?: any;
    refetchData?: () => void;
} & ModalProps;

export const UpdatePurchaseInformation = (props: Props) => {
    const { t } = useTranslation();
    
    const schema = z.object({
        name: z.string({message: "اسم العميل اجباري"}).min(2, { message: "اسم العميل يجب ان يكون اطول من حرفين" }),
        phone: z.string({message: "رقم الهاتف اجباري"}).min(9, { message: "رقم الهاتف يجب ان يكون 10 ارقام" }).max(10, { message: "رقم الهاتف يجب ان يكون 10 ارقام" }),
        email: z.string({message: "البريد الالكتروني اجباري"}).min(2, { message: "البريد الالكتروني يجب ان يكون اطول من حرفين" }),
    });
    const [loading, setLoading] = useState(false);
    const {onSubmit, reset, getInputProps, setValues, values} = useForm({ 
        initialValues: { price: 0, methodPay: ""},
        // validate: zodResolver(schema),
        // validateInputOnBlur: true,
        // validateInputOnChange: true
    });
    const [proofFile, setProofFiles] = useState<File[]>([]);

    useEffect(() => {
        if (props.opened && props.data !== null) {
            const client = props.data
            setValues({
                price: client?.purchaseInfo?.price,
                methodPay: client?.purchaseInfo?.methodPay
            })
        }
    }, [props.opened, props?.data])

    const onSubmitForm = ({price, methodPay}: any) => {
        setLoading(true)
        let data = new FormData();
        data.append('price', price);
        data.append('methodPay', methodPay);
        data.append('proof', proofFile[0]);
        
        client.put(`/addPurchaseInfoToClient/${props.data._id}`, data, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: "تم تعديل العميل", color: '#fff' });
            console.log(data);
            setLoading(false)
            typeof props.refetchData == "function" && props.refetchData()
            closeModal()
        })
        .catch(({response}) => {
            notifications.show({ message: "فشل تعديل العميل", color: '#fff' });
            setLoading(false)
        });
    }

    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={{padding: 20}}>
                <form onSubmit={onSubmit(onSubmitForm)} id="submit_form">
                    <Grid gutter={20} justify="flex-start">
                        <Grid.Col span={6} >
                            <NumberInput
                                label={"قيمة الدفع"}
                                placeholder={"قيمة الدفع"}
                                withAsterisk
                                {...getInputProps("price")}
                            />
                        </Grid.Col>
                        <Grid.Col span={6} >
                            <Select
                                label={"طريقة الدفع"}
                                placeholder={"طريقة الدفع"}
                                data={[
                                    {label: "كاش", value: "cash"},
                                    {label: "بريدي موب", value: "baridimob"},
                                    {label: "حوالة", value: "transfer"}
                                ]}
                                withAsterisk
                                {...getInputProps("methodPay")}
                            />
                        </Grid.Col>
                        
                        <Grid.Col span={12} >
                            <label style={{marginBottom: 20, fontSize: 16}}>{"إثبات الدفع"}</label>
                            <Dropzone
                                onDrop={(files: any) => setProofFiles(files)}
                                onReject={(files) => console.log('rejected files', files)}
                                maxSize={10 * 1024 ** 2}
                                accept={IMAGE_MIME_TYPE}
                            >
                                <Group justify="center" gap="xl" mih={100} style={{ pointerEvents: 'none' }}>
                                    <Dropzone.Accept>
                                        <IconUpload
                                            style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                                            stroke={1.5}
                                        />
                                    </Dropzone.Accept>
                                    <Dropzone.Reject>
                                        <IconX
                                            style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
                                            stroke={1.5}
                                        />
                                    </Dropzone.Reject>
                                    <Dropzone.Idle>
                                        <Stack align="center" justify="center" gap={2}>
                                            <IconCloudUpload size={40} style={{ color: 'var(--mantine-color-gray-8)' }} stroke={1} />
                                            <Text size="lg" mt={10}>{"اسحب الصورة هنا أو انقر لتحديد الصورة"}</Text>
                                            <Text size="sm" c="dimmed">{"قم بإرفاق الصورة ويجب ألا يتجاوز حجمها 5 ميجابايت"}</Text>
                                        </Stack>
                                    </Dropzone.Idle>
                                </Group>
                            </Dropzone>
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};