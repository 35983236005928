import { useCallback, useEffect, useState } from "react";
import { client } from "../lib/axiosClient";
import Cookies from 'universal-cookie';

const cookies = new Cookies(null, { path: '/' });

interface _Props {
    status?: string;
    referralCode?: string;
}

export const useClientsMarketer = () => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);

    const fetchData = useCallback(async ({status, referralCode}: _Props) => {
        setLoading(true);
        try {
            let params: any = {referralCode}
            if (status && status !== "") {
                params = {...params, status}
            }

            const response = await client.get(`/clients-marketer`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': cookies.get('token') || ""
                },
                params: params
            });
            
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            setData(response?.data)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, []);
    
    
    const refetch = useCallback(({status, referralCode}: _Props) => {
        fetchData({status, referralCode});
    }, [fetchData]);

      
    return { data, loading, error, fetchData, refetch };
}