import {ActionIcon, Badge, Button, CopyButton, Divider, Group, HoverCard, Image, List, Menu, Skeleton, Stack, Text, Tooltip, rem} from '@mantine/core';
import {IconDotsVertical, IconEdit, IconTrash, IconCircleXFilled, IconCircleCheckFilled, IconCheck, IconX, IconDatabaseOff, IconEye, IconCopy, IconRefresh, IconTrack, IconTruckDelivery, IconDashboard, IconCloudUpload, IconFlagDollar, IconCashBanknote, IconBrandWhatsapp, IconCalendar, IconArrowsExchange} from '@tabler/icons-react';
import DataTable, {TableStyles} from 'react-data-table-component';
import dayjs from "dayjs";
import Cookies from "universal-cookie";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUsers } from '../../api';

const cookies = new Cookies(null, { path: '/' });

const customStyles: TableStyles = {
    table: {
        style: {
            minHeight: 380,
            border: "2px solid #E0E2E7",
            borderRadius: "8px"
        },
    },
    headRow: {
        style: {
            borderRadius: "8px 8px 0 0",
            ":nth-child(1)": {
                borderRadius: "0 8px 0 0"
            },
            ":nth-last-child(1)": {
                borderRadius: "8px 0 0 0"
            }
        },
    },
    headCells: {
        style: {
            fontWeight: 800,
            fontSize: 14,
            background: "#F9F9FC",
            height: "56px",
            ":nth-child(1)": {
                background: "#F9F9FC",
            }
        }
    },
    cells: {
        style: {
            height: "60px"
        }
    },
    rows: {
        style: {
            '&:hover': {
				cursor: 'pointer',
			}
        }
    }
};

const urlDemos = {
    "nova": {
        baseURL: "https://demo-nova-api.bi3li.shop/api",
        urlAdmin: "https://demo-nova-admin.bi3li.shop",
        urlStore: "https://demo-nova.bi3li.shop"
    },
    "solo": {
        baseURL: "https://demo-solo-api.bi3li.shop/api",
        urlAdmin: "https://demo-solo-admin.bi3li.shop",
        urlStore: "https://demo-solo.bi3li.shop"
    },
    "nexa": {
        baseURL: "https://demo-nexa-api.bi3li.shop/api",
        urlAdmin: "https://demo-nexa-admin.bi3li.shop",
        urlStore: "https://demo-nexa.bi3li.shop"
    },
    "anaqati": {
        baseURL: "https://demo-anaqati-api2.bi3li.shop/api",
        urlAdmin: "https://demo-anaqati-admin.bi3li.shop",
        urlStore: "https://demo-anaqati.bi3li.shop"
    }
}

const genMsg = ({theme, email, pass}: {theme: "nova" | "nexa" | "solo", email: string, pass: string}) => {

    const urls = urlDemos[theme]
    
    let urlStore = urls?.urlStore
    let urlPanel = urls?.urlAdmin

    return `
مرحبًا %0A
هذه المعلومات الخاصة بالنسخة التجريبية الخاصة بك %0A
رابط لوحة التحكم : ${urlPanel} %0A
رابط المتجر : ${urlStore} %0A
البريد الالكتروني : ${email} %0A
كلمة المرور : ${pass} %0A
تحياتنا، فريق بيعلي
`
}

type Props = {
    setSelectedData: (data: any) => void;

    setOpenEditModal: (status: boolean) => void;
    setOpenDeleteModal: (status: boolean) => void;
    setOpenChangeStatusModal: (status: boolean) => void;
    setOpenEditTestingDateModal: (status: boolean) => void;
    setOpenEditStatusModal: (status: boolean) => void;
    setOpenShowModal: (status: boolean) => void;
    setOpenEditConfermerModal: (status: boolean) => void;
    data?: any;
    loading?: boolean;
};

export const HistoryClientsTabel = ({data, loading, setSelectedData, setOpenEditModal, setOpenDeleteModal, setOpenShowModal, setOpenChangeStatusModal, setOpenEditTestingDateModal, setOpenEditStatusModal, setOpenEditConfermerModal}: Props) => {
    const { t } = useTranslation();
    const [role, setRole] = useState("");
    const [users, setUsers] = useState<any[]>([]);
    const {data: dataUser, refetch} = useUsers()

    useEffect(() => {
        if (dataUser?.length >= 0) {
            setUsers(dataUser)
        }
    }, [dataUser])

    const getConfirmed = (confirmedID: string) => {
        if (!confirmedID || confirmedID == "") return null
        const confirmed = users?.filter((item: any) => item._id == confirmedID)
        return confirmed?.[0]
    }

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])

    const getLastTrace = (data: any) => {
        if (!data) return []
        const length = data.length
        return data[length-1]
    }

    const columns = [
        {name: "اسم العميل", selector: (row: any, index: number) => row?.name, minWidth: "170px"},
        {name: "رقم الهاتف", selector: (row: any, index: number) => (
            <Group justify='center' align='center' wrap='nowrap' gap={5}>
                <Text size='sm'>{row?.phone}</Text>
                {row?.connectHistory?.length === 0 
                    ? <ActionIcon 
                        variant={"transparent"} color={"gray"}
                        component='a' target='_blank'
                        href={`https://wa.me/${row?.phone}?text=${genMsg({theme: row?.theme, email: row?.email || "", pass: row?.password || ""})}`}
                    >
                        <IconBrandWhatsapp size="1.125rem" />
                    </ActionIcon>
                    : null
                }
                
            </Group>
        ), minWidth: "150px"},
        {name: "البريد الالكتروني", selector: (row: any, index: number) => row?.email, minWidth: "220px"},
        {name: "الثيم", selector: (row: any, index: number) => (
            row?.theme === "nova" ? <Badge radius={'sm'} color='green' variant='filled'>{"Nova"}</Badge> 
                : row?.theme === "nexa" ? <Badge radius={'sm'} color='#d44c2e' variant='filled'>{"Nexa"}</Badge> 
                    : row?.theme === "solo" ? <Badge radius={'sm'} color='purple' variant='filled'>{"Solo"}</Badge> 
                    : row?.theme === "anaqati" ? <Badge radius={'sm'} color='#b07d10' variant='filled'>{"Anaqati"}</Badge> : null
        ), minWidth: "60px", sortable: true, sortFunction: (a: any, b: any) => a?.theme > b?.theme ? 1 : -1},

        {name: "مدة التجريب", selector: (row: any) => {
            if (row?.dateTesting) {
                const dateB = dayjs(row?.dateTesting).add(3, 'days')
                const remainingTime = parseFloat(Number(dateB.diff(new Date(), 'hours')).toFixed(2))
                
                if (remainingTime > 24) {
                    return <Text c={'green'} size='sm'>{`باقي ${remainingTime} ساعة`}</Text>
                } else if (remainingTime < 24 && remainingTime > 6) {
                    return <Text c={'yellow'} size='sm'>{`باقي ${remainingTime} ساعة`}</Text>
                } else if (remainingTime < 6 && remainingTime > 0) {
                    return <Text c={'red'} size='sm'>{`باقي ${remainingTime} ساعة`}</Text>
                } else if (remainingTime <= 0) {
                    return <Text c={'gray'} size='sm'>{"انتهت فترت التجريب"}</Text>
                }
            } else {
                return ""
            }
        }, minWidth: "170px", sortable: true, sortFunction: (a: any, b: any) => a?.dateTesting > b?.dateTesting ? 1 : -1 },

        {name: "حالة الدخول", selector: (row: any) => (
            row?.connectHistory?.length > 0 ? <IconCircleCheckFilled color='green' /> : <IconCircleXFilled color='red' />
        ), minWidth: "150px" },
        {name: "تاريخ اخر حالة", selector: (row: any) => (
            getLastTrace(row?.timeLine)?.createdAt ? dayjs(getLastTrace(row?.timeLine)?.createdAt).locale("ar").fromNow() : ""
        ), minWidth: "150px" },
        {name: "اخر حالة", selector: (row: any) => (
            getLastTrace(row?.timeLine)?.status ? <Badge fw={'normal'}>{getLastTrace(row?.timeLine)?.status}</Badge> : ""
        ), minWidth: "140px" },

        {name: "مؤكد الطلب", selector: (row: any) => (
            getConfirmed(row?.confirmedID) ? getConfirmed(row?.confirmedID)?.name : ""
        ), minWidth: "140px", sortable: true, sortFunction: (a: any, b: any) => a?.confirmedID > b?.confirmedID ? 1 : -1 },

        {name: "تاريخ التحديث", selector: (row: any) => (
            <Tooltip label={dayjs(row?.updatedAt).format("YYYY-MM-DD HH:mm:ss")}>
                <Text size='12px'>{dayjs(row?.updatedAt).locale("ar").fromNow()}</Text>
            </Tooltip>
        ), minWidth: "140px", sortable: true, sortFunction: (a: any, b: any) => a?.updatedAt > b?.updatedAt ? 1 : -1 },
        {name: "اعادة التسجيل", selector: (row: any) => (
            row?.registerAgain > 0 ? <IconCircleCheckFilled color='green' /> : <IconCircleXFilled color='red' />
        ), minWidth: "150px" },
        {name: "تاريخ التسجيل", selector: (row: any) => (
            <Tooltip label={dayjs(row?.createdAt).format("YYYY-MM-DD HH:mm:ss")}>
                <Text size='12px'>{dayjs(row?.createdAt).locale("ar").fromNow()}</Text>
            </Tooltip>
        ), minWidth: "140px", sortable: true, sortFunction: (a: any, b: any) => a?.createdAt > b?.createdAt ? 1 : -1 },

        {name: "الخيارات", cell: (row: any) => (
            <Group wrap={"nowrap"} justify={"center"} w={"100%"} gap={5}>
                <ActionIcon
                    variant={"transparent"} color={"#667085"}
                    onClick={() => {
                        setSelectedData(row)
                        setOpenShowModal(true)
                    }}
                >
                    <IconEye size="1.125rem" />
                </ActionIcon>

                <Tooltip label="تجديد فترة التجريب">
                    <ActionIcon
                        variant={"transparent"} color={"#667085"}
                        onClick={() => {
                            setSelectedData(row)
                            setOpenEditTestingDateModal(true)
                        }}
                    >
                        <IconCalendar size="1.125rem" />
                    </ActionIcon>
                </Tooltip>
                
                {["admin"].includes(role)
                    ? <Tooltip label="تغيير المؤكد">
                        <ActionIcon
                            variant={"transparent"} color={"#667085"}
                            onClick={() => {
                                setSelectedData(row)
                                setOpenEditConfermerModal(true)
                            }}
                        >
                            <IconArrowsExchange size="1.125rem" />
                        </ActionIcon>
                    </Tooltip>
                    : null
                }

                <Menu shadow="md" width={220}>
                    <Menu.Target>
                        <ActionIcon variant={"transparent"} color={"darck"}>
                            <IconDotsVertical size="1.125rem" />
                        </ActionIcon>
                    </Menu.Target>

                    <Menu.Dropdown>
                        <Menu.Item
                            leftSection={<IconEdit size={14} />}
                            onClick={() => {
                                setSelectedData(row)
                                setOpenChangeStatusModal(true)
                            }}
                        >{"تغيير حالة الطلب"}</Menu.Item>

                        <Divider />
                        <Menu.Item
                            leftSection={<IconEdit size={14} />}
                            onClick={() => {
                                setSelectedData(row)
                                setOpenEditModal(true)
                            }}
                        >{t('tables.orders.edit')}</Menu.Item>
                        
                        {["admin"].includes(role)
                            ? <Menu.Item
                                leftSection={<IconEdit size={14} />}
                                onClick={() => {
                                    setSelectedData(row._id)
                                    setOpenEditStatusModal(true)
                                }}
                            >{"تغيير حالة العميل مباشرة"}</Menu.Item>
                            : null
                        }
                        {["admin"].includes(role)
                            ? <Menu.Item
                                leftSection={<IconTrash size={14} />}
                                onClick={() => {
                                    setSelectedData(row._id)
                                    setOpenDeleteModal(true)
                                }}
                            >{t('tables.orders.delete')}</Menu.Item>
                            : null
                        }
                        
                    </Menu.Dropdown>
                </Menu>
            </Group>
        ), allowOverflow: true, button: true, minWidth: '130px' }
    ];

    return (
        <DataTable
            // @ts-ignore
            columns={columns}
            data={data}
            customStyles={customStyles}
            highlightOnHover
            persistTableHead={true}
            progressPending={loading || false}
            progressComponent={<LoadingTable />}
            onRowDoubleClicked={(row, event) => {
                setSelectedData(row)
                setOpenShowModal(true)
            }}
            noDataComponent={
                <Stack align='center' justify='center' p={10}>
                    <Image src={"/emptyData.png"} mah={150} w={"auto"} />
                    <Text size='14px' c={"#667085"}>{t('tables.emptyLable')}</Text>
                </Stack>
            }

            pagination={true}
            paginationComponentOptions={{
                rowsPerPageText: t('tables.rangeSeparatorText'),
                rangeSeparatorText: t('tables.rangeSeparatorText')
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100, 200]}
            paginationPerPage={100}
        />
    );
};


const LoadingTable = () => {
    const numRows = [0,0,0];
    return (
        <Stack w={"100%"} p={0} m={0} gap={0}>
            {numRows.map((item, index) => (
                <RowSkeleton key={index}/>
            ))}
        </Stack>
    )
}

const RowSkeleton = () => {
    
    return (
        <Group w={"100%"} mih={48} align='stretch' gap={0} wrap='nowrap' style={{borderBottom: "2px solid #E0E2E7"}}>
            <Group h={60} miw={"250px"} px={16} justify='flex-start' align='center' gap={8} wrap='nowrap'>
                <Skeleton width={44} height={44} />
                <Stack justify='center' align='flex-start' gap={5}>
                    <Skeleton height={14} width={120} />
                    <Skeleton height={12} width={60} />
                </Stack>
            </Group>
            <Group h={60} miw={"170px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"120px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"110px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"110px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"140px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"140px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"140px"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"100px"} px={16} justify='flex-start' align='center'>
                <Group wrap={"nowrap"} justify={"center"} w={"100%"} gap={2}>
                    <Skeleton height={18} width={18} />
                    <Skeleton height={18} width={18} />
                </Group>
            </Group>
        </Group>
    );
}