import { ActionIcon, Badge, Box, Button, Checkbox, CloseButton, ColorInput, CopyButton, Divider, Grid, Group, Image, NumberInput, PasswordInput, Select, Stack, Switch, Tabs, Text, TextInput, Textarea, Tooltip, rem } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { client } from "../../lib/axiosClient";
import { IconApi, IconBuilding, IconBuildingStore, IconCheck, IconCloudUpload, IconCopy, IconDashboard, IconFolder, IconKey, IconMail, IconPassword, IconPhoto, IconShoppingBag, IconUpload, IconX } from "@tabler/icons-react";
import { zodResolver } from 'mantine-form-zod-resolver';
import { z } from 'zod';
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { Dropzone, IMAGE_MIME_TYPE, MIME_TYPES } from "@mantine/dropzone";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

export const AddProject = (props: Props) => {
    const { t } = useTranslation();
    
    const schema = z.object({
        name: z.string({message: "اسم العميل اجباري"}).min(2, { message: "اسم العميل يجب ان يكون اطول من حرفين" }),
        phone: z.string({message: "رقم الهاتف اجباري"}).min(10, { message: "رقم الهاتف يجب ان يكون 10 ارقام" }).max(10, { message: "رقم الهاتف يجب ان يكون 10 ارقام" }),
        email: z.string({message: "البريد الالكتروني اجباري"}).min(2, { message: "البريد الالكتروني يجب ان يكون اطول من حرفين" }),
    });
    const [loading, setLoading] = useState(false);
    const {onSubmit, reset, getInputProps, setValues, values} = useForm({
        initialValues: { 
            rootFloderName: "",
            pathRoot: "",
            prefixDomain: "",
            dataBaseName: "",
            dataBaseUser: "",
            dataBasePass: "",
            nodeProjectName: ""
        },
        // validate: zodResolver(schema),
        // validateInputOnBlur: true,
        // validateInputOnChange: true
    });
    const [apiFiles, setApiFiles] = useState<File[]>([]);
    const [adminFiles, setAdminFiles] = useState<File[]>([]);
    const [clientFiles, setClientFiles] = useState<File[]>([]);
    const [dataProject, setDataProject] = useState<any>(null);


    const onSubmitForm = ({rootFloderName, pathRoot, prefixDomain, dataBaseName, dataBaseUser, dataBasePass, nodeProjectName}: any) => {
        setLoading(true)
        let data = new FormData();
        data.append('rootFloderName', rootFloderName);
        data.append('pathRoot', pathRoot);
        data.append('prefixDomain', prefixDomain);
        data.append('dataBaseName', dataBaseName);
        data.append('dataBaseUser', dataBaseUser);
        data.append('dataBasePass', dataBasePass);
        data.append('nodeProjectName', nodeProjectName);

        data.append('apiFiles', apiFiles[0]);
        data.append('adminFiles', adminFiles[0]);
        data.append('clientFiles', clientFiles[0]);

        console.log({
            rootFloderName, pathRoot, prefixDomain,
            dataBaseName, dataBaseUser, dataBasePass,
            nodeProjectName, 'apiFiles': apiFiles[0],
            'adminFiles': adminFiles[0], 'clientFiles': clientFiles[0]
        });
        
        
        client.post(`/push`, data, {
            baseURL: process.env.REACT_APP_SOCKET_URL,
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: "تم اضافة المشروع", color: '#fff' });
            console.log(data);
            if (data && "email" in data) {
                setDataProject(data)
            }
            setLoading(false)
            typeof props.refetchData == "function" && props.refetchData()
            // closeModal()
        })
        .catch(({response}) => {
            notifications.show({ message: "فشل اضافة المشروع", color: '#fff' });
            setLoading(false)
        });
    }

    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
        setDataProject(null)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            {!dataProject
                ? <Box style={{padding: 20}}>
                    <form onSubmit={onSubmit(onSubmitForm)} id="submit_form">
                        <Grid gutter={20} justify="flex-start">
                            <Grid.Col span={6} >
                                <TextInput
                                    label={"اسم المشروع"}
                                    placeholder={"اسم المشروع"}
                                    description={"اسم المشروع بدون المسار"}
                                    withAsterisk
                                    {...getInputProps("rootFloderName")}
                                />
                            </Grid.Col>
                            <Grid.Col span={6} >
                                <TextInput
                                    label={"مسار رفع المشروع"}
                                    placeholder={"مسار رفع المشروع"}
                                    description={"مسار رفع المشروع الخاص بالعملاء:  /www/wwwroot/TajerClients"}
                                    withAsterisk
                                    {...getInputProps("pathRoot")}
                                />
                            </Grid.Col>
                            <Grid.Col span={6} >
                                <TextInput
                                    label={"جزء الدومين المشترك"}
                                    placeholder={"جزء الدومين المشترك"}
                                    description={"*-api.bi3li.shop, *-admin.bi3li.shop, *.bi3li.shop"}
                                    withAsterisk
                                    {...getInputProps("prefixDomain")}
                                />
                            </Grid.Col>
                            <Grid.Col span={6} >
                                <TextInput
                                    label={"اسم مشروع Api"}
                                    placeholder={"اسم مشروع Api"}
                                    description={"احسن شكل للكتابة Api(name project)"}
                                    withAsterisk
                                    {...getInputProps("nodeProjectName")}
                                />
                            </Grid.Col>
                            <Grid.Col span={6} >
                                <TextInput
                                    label={"اسم قاعدة البيانات"}
                                    placeholder={"اسم قاعدة البيانات"}
                                    description={"الاسم يجب ان لايحتوي على رموز مميزة او فراغ"}
                                    withAsterisk
                                    {...getInputProps("dataBaseName")}
                                />
                            </Grid.Col>
                            <Grid.Col span={6} >
                                <TextInput
                                    label={"اسم مستخدم قاعدة البيانات"}
                                    placeholder={"اسم مستخدم قاعدة البيانات"}
                                    description={"الاحسن يكون بنفس اسم قاعدة البيانات"}
                                    withAsterisk
                                    {...getInputProps("dataBaseUser")}
                                />
                            </Grid.Col>
                            <Grid.Col span={6} >
                                <TextInput
                                    label={"كلمة مرور قاعدة البيانات"}
                                    placeholder={"كلمة مرور قاعدة البيانات"}
                                    description={"الاحسن يكون بنفس اسم قاعدة البيانات"}
                                    withAsterisk
                                    {...getInputProps("dataBasePass")}
                                />
                            </Grid.Col>

                            <Grid.Col span={12} >
                                <label style={{marginBottom: 20, fontSize: 16}}>{"ملف ال Api"}</label>
                                <Dropzone
                                    onDrop={(files: any) => setApiFiles(files)}
                                    onReject={(files) => console.log('rejected files', files)}
                                    maxSize={10 * 1024 ** 2}
                                    accept={[MIME_TYPES.zip]}
                                >
                                    <Group justify="center" gap="xl" mih={100} style={{ pointerEvents: 'none' }}>
                                        <Dropzone.Accept>
                                            <IconUpload
                                                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                                                stroke={1.5}
                                            />
                                        </Dropzone.Accept>
                                        <Dropzone.Reject>
                                            <IconX
                                                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
                                                stroke={1.5}
                                            />
                                        </Dropzone.Reject>
                                        <Dropzone.Idle>
                                            <Stack align="center" justify="center" gap={2}>
                                                <IconCloudUpload size={40} style={{ color: 'var(--mantine-color-gray-8)' }} stroke={1} />
                                                <Text size="lg" mt={10}>{"اسحب ملف zip هنا أو انقر لتحديد الملف"}</Text>
                                                <Text size="sm" c="dimmed">{"قم بإرفاق ملف zip، ويجب ألا يتجاوز حجمه 10 ميجابايت"}</Text>
                                            </Stack>
                                        </Dropzone.Idle>
                                    </Group>
                                </Dropzone>
                            </Grid.Col>
                            <Grid.Col span={12} >
                                <label style={{marginBottom: 20, fontSize: 16}}>{"ملف لوحة التحكم"}</label>
                                <Dropzone
                                    onDrop={(files: any) => setAdminFiles(files)}
                                    onReject={(files) => console.log('rejected files', files)}
                                    maxSize={10 * 1024 ** 2}
                                    accept={[MIME_TYPES.zip]}
                                >
                                    <Group justify="center" gap="xl" mih={100} style={{ pointerEvents: 'none' }}>
                                        <Dropzone.Accept>
                                            <IconUpload
                                                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                                                stroke={1.5}
                                            />
                                        </Dropzone.Accept>
                                        <Dropzone.Reject>
                                            <IconX
                                                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
                                                stroke={1.5}
                                            />
                                        </Dropzone.Reject>
                                        <Dropzone.Idle>
                                            <Stack align="center" justify="center" gap={2}>
                                                <IconCloudUpload size={40} style={{ color: 'var(--mantine-color-gray-8)' }} stroke={1} />
                                                <Text size="lg" mt={10}>{"اسحب ملف zip هنا أو انقر لتحديد الملف"}</Text>
                                                <Text size="sm" c="dimmed">{"قم بإرفاق ملف zip، ويجب ألا يتجاوز حجمه 10 ميجابايت"}</Text>
                                            </Stack>
                                        </Dropzone.Idle>
                                    </Group>
                                </Dropzone>
                            </Grid.Col>
                            <Grid.Col span={12} >
                                <label style={{marginBottom: 20, fontSize: 16}}>{"ملف المتجر"}</label>
                                <Dropzone
                                    onDrop={(files: any) => setClientFiles(files)}
                                    onReject={(files) => console.log('rejected files', files)}
                                    maxSize={10 * 1024 ** 2}
                                    accept={[MIME_TYPES.zip]}
                                >
                                    <Group justify="center" gap="xl" mih={100} style={{ pointerEvents: 'none' }}>
                                        <Dropzone.Accept>
                                            <IconUpload
                                                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                                                stroke={1.5}
                                            />
                                        </Dropzone.Accept>
                                        <Dropzone.Reject>
                                            <IconX
                                                style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
                                                stroke={1.5}
                                            />
                                        </Dropzone.Reject>
                                        <Dropzone.Idle>
                                            <Stack align="center" justify="center" gap={2}>
                                                <IconCloudUpload size={40} style={{ color: 'var(--mantine-color-gray-8)' }} stroke={1} />
                                                <Text size="lg" mt={10}>{"اسحب ملف zip هنا أو انقر لتحديد الملف"}</Text>
                                                <Text size="sm" c="dimmed">{"قم بإرفاق ملف zip، ويجب ألا يتجاوز حجمه 10 ميجابايت"}</Text>
                                            </Stack>
                                        </Dropzone.Idle>
                                    </Group>
                                </Dropzone>
                            </Grid.Col>
                        </Grid>
                    </form>
                </Box>
                : <Box>
                    <Grid gutter={20} justify="flex-start">
                        <Grid.Col span={12}>
                            <Box display={"flex"} style={{padding: 10, border: "1px solid #aaa", gap: 10, borderRadius: 5, alignItems: "center"}} >
                                <IconFolder size={18} />
                                <Text size="xs" ta={"left"}>{dataProject?.path}</Text>
                                
                                <CopyButton value={dataProject?.path} timeout={2000}>
                                    {({ copied, copy }) => (
                                        <Tooltip label={copied ? 'تم النسخ' : 'نسخ'} withArrow position="right">
                                            <ActionIcon size={20} color={copied ? 'teal' : 'dark'} variant="subtle" onClick={copy} ml={0} mr={"auto"}>
                                                {copied ? (<IconCheck size={16} />) : (<IconCopy size={16} />)}
                                            </ActionIcon>
                                        </Tooltip>
                                    )}
                                </CopyButton>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Box display={"flex"} style={{padding: 10, border: "1px solid #aaa", gap: 10, borderRadius: 5, alignItems: "center"}} >
                                <IconMail size={18} />
                                <Text size="xs" ta={"left"}>{dataProject?.email}</Text>
                                
                                <CopyButton value={dataProject?.email} timeout={2000}>
                                    {({ copied, copy }) => (
                                        <Tooltip label={copied ? 'تم النسخ' : 'نسخ'} withArrow position="right">
                                            <ActionIcon size={20} color={copied ? 'teal' : 'dark'} variant="subtle" onClick={copy} ml={0} mr={"auto"}>
                                                {copied ? (<IconCheck size={16} />) : (<IconCopy size={16} />)}
                                            </ActionIcon>
                                        </Tooltip>
                                    )}
                                </CopyButton>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Box display={"flex"} style={{padding: 10, border: "1px solid #aaa", gap: 10, borderRadius: 5, alignItems: "center"}} >
                                <IconKey size={18} />
                                <Text size="xs" ta={"left"}>{dataProject?.password}</Text>
                                
                                <CopyButton value={dataProject?.password} timeout={2000}>
                                    {({ copied, copy }) => (
                                        <Tooltip label={copied ? 'تم النسخ' : 'نسخ'} withArrow position="right">
                                            <ActionIcon size={20} color={copied ? 'teal' : 'dark'} variant="subtle" onClick={copy} ml={0} mr={"auto"}>
                                                {copied ? (<IconCheck size={16} />) : (<IconCopy size={16} />)}
                                            </ActionIcon>
                                        </Tooltip>
                                    )}
                                </CopyButton>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Box display={"flex"} style={{padding: 10, border: "1px solid #aaa", gap: 10, borderRadius: 5, alignItems: "center"}} >
                                <IconApi size={18} />
                                <Text size="xs" ta={"left"}>{dataProject?.apiDomainName}</Text>
                                
                                <CopyButton value={dataProject?.apiDomainName} timeout={2000}>
                                    {({ copied, copy }) => (
                                        <Tooltip label={copied ? 'تم النسخ' : 'نسخ'} withArrow position="right">
                                            <ActionIcon size={20} color={copied ? 'teal' : 'dark'} variant="subtle" onClick={copy} ml={0} mr={"auto"}>
                                                {copied ? (<IconCheck size={16} />) : (<IconCopy size={16} />)}
                                            </ActionIcon>
                                        </Tooltip>
                                    )}
                                </CopyButton>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Box display={"flex"} style={{padding: 10, border: "1px solid #aaa", gap: 10, borderRadius: 5, alignItems: "center"}} >
                                <IconDashboard size={18} />
                                <Text size="xs" ta={"left"}>{dataProject?.adminDomainName}</Text>
                                
                                <CopyButton value={dataProject?.adminDomainName} timeout={2000}>
                                    {({ copied, copy }) => (
                                        <Tooltip label={copied ? 'تم النسخ' : 'نسخ'} withArrow position="right">
                                            <ActionIcon size={20} color={copied ? 'teal' : 'dark'} variant="subtle" onClick={copy} ml={0} mr={"auto"}>
                                                {copied ? (<IconCheck size={16} />) : (<IconCopy size={16} />)}
                                            </ActionIcon>
                                        </Tooltip>
                                    )}
                                </CopyButton>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Box display={"flex"} style={{padding: 10, border: "1px solid #aaa", gap: 10, borderRadius: 5, alignItems: "center"}} >
                                <IconShoppingBag size={18} />
                                <Text size="xs" ta={"left"}>{dataProject?.storeDomainName}</Text>
                                
                                <CopyButton value={dataProject?.storeDomainName} timeout={2000}>
                                    {({ copied, copy }) => (
                                        <Tooltip label={copied ? 'تم النسخ' : 'نسخ'} withArrow position="right">
                                            <ActionIcon size={20} color={copied ? 'teal' : 'dark'} variant="subtle" onClick={copy} ml={0} mr={"auto"}>
                                                {copied ? (<IconCheck size={16} />) : (<IconCopy size={16} />)}
                                            </ActionIcon>
                                        </Tooltip>
                                    )}
                                </CopyButton>
                            </Box>
                        </Grid.Col>
                    </Grid>
                </Box>
            }
        </Modal>
    );
};