import React, { useEffect, useState } from 'react';
import { client } from '../../lib/axiosClient';
import { HistoryClientsTabel, UsersTabel } from '../../componants/Tables';
import { HeadPage } from '../../componants';
import { searchSortedData, sortedData, sortedDataByUpdateDate } from '../../lib/sort';
import { useDisclosure } from '@mantine/hooks';
import { AddUser, ChangeStatusOrder, DeleteClient, DeleteUser, ShowClientsMarketer, UpdateClient, UpdateClientStatus, UpdateClientTestingDate, UpdateConfermer, UpdateUser, UpdateUsers } from '../../componants/Modal';
import Cookies from 'universal-cookie';
import { useHistoryClients, useUsers } from '../../api';
import { useTranslation } from 'react-i18next';
import { Container, Stack, Text } from '@mantine/core';
import { IconLock } from '@tabler/icons-react';
import { ShowClientDrawer } from '../../componants/Drawer';
const cookies = new Cookies(null, { path: '/' });

export function HistoryClients () {
    const { t } = useTranslation();
    const [users, setUsers] = useState<any[]>([]);
    const [clientsSorted, setClientsSorted] = useState<any[]>([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openEditConfermerModal, setOpenEditConfermerModal] = useState(false);
    const [openEditTestingDateModal, setOpenEditTestingDateModal] = useState(false);
    const [openEditStatusModal, setOpenEditStatusModal] = useState(false);
    const [openShowModal, setOpenShowModal] = useState(false);
    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const {loading, error, data: dataClients, refetch: refetchHistoryClients} = useHistoryClients()
    const [role, setRole] = useState("");
    // const theme = useMantineTheme();

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])

    useEffect(() => {
        if (dataClients?.length >= 0) {
            setUsers(dataClients)
        }
    }, [dataClients])

    useEffect(() => {
        if (users && users?.length >= 0) {
            const newData = sortedDataByUpdateDate(users)
            setClientsSorted(newData)
        }
    }, [users])
    
    if (!["admin"].includes(role)) {
        return (
            <Container>
                <Stack align='center' justify='center' h={"calc(100vh - 130px)"}>
                    <IconLock size={45} strokeWidth={1.5} />
                    <Text>ليس لديك صلحيات للوصول لهذه الصفحة</Text>
                </Stack>
            </Container>
        )
    }

    return (
        <>
            <HeadPage
                page={"سجل التحديثات"}
                links={[
                    { title: t('usersPage.links.link01'), href: '/dashboard' },
                    { title: "قائمة تحديثات العملاء اليوم", href: '' }
                ]}
                labelCreate={""}
                labelExport=''
                onCreate={() => console.log()}
                onExport={() => console.log()}
                onRefresh={() => refetchHistoryClients()}
                hiddenExport={true}
                hiddenRefresh={false}
            />
            
            <HistoryClientsTabel
                data={clientsSorted}
                setOpenDeleteModal={setOpenDeleteModal}
                setOpenEditModal={setOpenEditModal}
                setOpenShowModal={setOpenShowModal}
                setOpenChangeStatusModal={setOpenChangeStatusModal}
                setOpenEditTestingDateModal={setOpenEditTestingDateModal}
                setOpenEditStatusModal={setOpenEditStatusModal}
                setOpenEditConfermerModal={setOpenEditConfermerModal}
                setSelectedData={setSelectedData}
                loading={loading}
            />

            <UpdateClient title={"تعديل العميل"}  data={selectedData} refetchData={refetchHistoryClients} opened={openEditModal} onClose={() => setOpenEditModal(false)} />
            <DeleteClient data={selectedData} refetchData={refetchHistoryClients} opened={openDeleteModal} onClose={() => setOpenDeleteModal(false)} />
            <ChangeStatusOrder data={selectedData} status='all' refetchData={refetchHistoryClients} opened={openChangeStatusModal} onClose={() => setOpenChangeStatusModal(false)} />
            <UpdateClientTestingDate title={"تجديد فترت تجريب العميل"}  data={selectedData} refetchData={refetchHistoryClients} opened={openEditTestingDateModal} onClose={() => setOpenEditTestingDateModal(false)} />
            <UpdateClientStatus title={"تغيير حالة العميل مباشرة"}  data={selectedData} refetchData={refetchHistoryClients} opened={openEditStatusModal} onClose={() => setOpenEditStatusModal(false)} />
            <ShowClientDrawer title={"تفاصيل العميل"} data={selectedData} opened={openShowModal} onClose={() => setOpenShowModal(false)} />

            <UpdateConfermer title={"تغيير موكد الطلبات"} refetchData={refetchHistoryClients} data={selectedData} opened={openEditConfermerModal} onClose={() => setOpenEditConfermerModal(false)} />
        </>
    );
}