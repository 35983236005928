import React, { useEffect, useState } from 'react';
import { client } from '../../lib/axiosClient';
import { ClientsMarketerTabel, InvocesTabel, UsersTabel } from '../../componants/Tables';
import { HeadPage } from '../../componants';
import { sortedData } from '../../lib/sort';
import Cookies from 'universal-cookie';
import { useClientsMarketer, useInvocesMarketer, useUser, useUsers } from '../../api';
import { useTranslation } from 'react-i18next';
import { Container, Group, SegmentedControl, Stack, Tabs, Text } from '@mantine/core';
import { IconLock, IconMail, IconPhone, IconUser } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import Avatar from 'react-avatar';
import classes from './../../styles/Product.module.css';
import { ChangeStatusInvoiceMarketer, ShowInvoiceMarketer, ShowIvoiceSites } from '../../componants/Modal';
const cookies = new Cookies(null, { path: '/' });

export function Marketer () {
    const { t } = useTranslation();
    let { id } = useParams();
    const [role, setRole] = useState("");

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openShowModal, setOpenShowModal] = useState(false);

    const [selectedData, setSelectedData] = useState<any>(null);

    const [ordersSorted, setOrdersSorted] = useState<any[]>([]);
    const [invocesSorted, setInvocesSorted] = useState<any[]>([]);
    const [activeTab, setActiveTab] = useState<string>('order');
    const {loading, data: dataOrders, fetchData} = useClientsMarketer()
    const {loading: loadingInvoces, data: dataInvoces, fetchData: getAllInvoces, refetch: refetchAllInvoces} = useInvocesMarketer()
    const {data: dataUser, fetchData: getUser} = useUser()

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])

    useEffect(() => {
        if (id && id !== "") {
            getUser(id)
        }
    }, [id])

    useEffect(() => {
        if (dataUser && dataUser !== null && activeTab === "order") {
            fetchData({
                referralCode: dataUser?.referralCode
            })
        } else if (dataUser && dataUser !== null && activeTab === "invoces") {
            getAllInvoces({
                idMarketer: dataUser?._id
            })
        }
    }, [dataUser, activeTab])

    useEffect(() => {
        if (dataOrders && dataOrders.length >= 0) {
            const newData = sortedData(dataOrders)
            setOrdersSorted(newData)
        }
    }, [dataOrders])

    useEffect(() => {
        if (dataInvoces && dataInvoces.length >= 0) {
            const newData = sortedData(dataInvoces)
            setInvocesSorted(newData)
        }
    }, [dataInvoces])

    
    if (!["admin"].includes(role)) {
        return (
            <Container>
                <Stack align='center' justify='center' h={"calc(100vh - 130px)"}>
                    <IconLock size={45} strokeWidth={1.5} />
                    <Text>ليس لديك صلحيات للوصول لهذه الصفحة</Text>
                </Stack>
            </Container>
        )
    }

    return (
        <>
            <HeadPage
                page={`بروفايل المسوق (${dataUser?.name})`}
                links={[
                    { title: t('usersPage.links.link01'), href: '/dashboard' },
                    { title: "المستخدمين", href: '/dashboard/users' },
                    { title: "طلبات المسوق", href: '' }
                ]}
                labelCreate={""}
                labelExport=''
                onCreate={() => console.log()}
                onExport={() => console.log()}
                hiddenExport={true}
            />
            
            <Group justify='space-between' align='flex-end' mb={20}>
                <SegmentedControl
                    withItemsBorders={false} 
                    value={activeTab}
                    onChange={setActiveTab}
                    data={[
                        {label: "الطلبات", value: "order"},
                        {label: "الفواتير", value: "invoces"}
                    ]}
                    styles={{
                        root: {
                            border: "1px solid #E0E2E7",
                            background: "#fff",
                            height: 40,
                            alignItems: "center",
                            overflowX: 'auto'
                        },
                        indicator: {
                            background: "#F2F7FB", display: "none",
                            height: 30, minHeight: 30, maxHeight: 30,
                            boxShadow: "none"
                        }
                    }}
                    classNames={{
                        control: classes.control
                    }}
                />
                
            </Group>

            <Tabs 
                defaultValue={activeTab} value={activeTab}
                //@ts-ignore
                onChange={setActiveTab}
            >
                <Tabs.Panel value="order" >
                    <ClientsMarketerTabel
                        data={ordersSorted}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="invoces" >
                    <InvocesTabel
                        data={invocesSorted}
                        setOpenEditModal={setOpenEditModal}
                        setOpenShowModal={setOpenShowModal}
                        setSelectedData={setSelectedData}
                        loading={loading}
                    />
                </Tabs.Panel>
            </Tabs>
            <ChangeStatusInvoiceMarketer data={selectedData} refetchData={() => refetchAllInvoces({idMarketer: dataUser?._id})}  opened={openEditModal} onClose={() => setOpenEditModal(false)} />
            <ShowInvoiceMarketer data={selectedData} opened={openShowModal} onClose={() => setOpenShowModal(false)} />
        </>
    );
}